/* eslint-disable complexity */
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import update from 'immutability-helper';
import {isArray} from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import maxBy from 'lodash/maxBy';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  Dashboard,
  DashboardDrilldownAction,
  DashboardEntity,
  DashboardFilter,
  DashboardUpsertInputBody,
} from '../../interfaces/Dashboard';
import reduxActions from '../../redux/actions';
import {PanelCode} from '../../utils/panels';
import AccessControl from '../common/AccessControl';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';
import {DashboardPanelSelectButton} from '../dashboard-panels/DashboardPanelSelectButton';
import {DashboardProvider} from './DashboardContext';
import DashboardEntityList from './entities/DashboardEntityList';

interface Props {
  pk: number;
  item?: Dashboard;
  prefetch?: boolean;
  onSubmitted?: (item: Dashboard) => void;
}

const getDashboardInput = (item?: Dashboard): DashboardUpsertInputBody => {
  return {
    name: item?.name ?? null,
    data: {
      entities:
        item?.data.entities?.map((i) => ({
          ...i,
          id: i.id ?? Math.random().toString(16).substring(2, 14),
        })) ?? [],
    },
  };
};

const getDashboardFilter = (
  input?: DashboardUpsertInputBody
): DashboardFilter => {
  const filter: DashboardFilter = {};

  input?.data?.entities?.forEach((el) => {
    filter[el.id] = {
      chartType: 'bar',
      params: {},
    };
  });

  return filter;
};

const DashboardItem = ({pk, item, prefetch = true, onSubmitted}: Props) => {
  const {t} = useTranslation();
  const me = useAppSelector(({app}) => app.me);
  const reduxDispatch = useAppDispatch();

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<Dashboard | undefined>(
    cloneDeep(item)
  );

  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<Dashboard>(`${apiBaseUrl}/dashboard/${pk}`);
      if (!isEqual(item, resp.data)) {
        setFilter(getDashboardFilter(resp.data));
        setFetchedData(resp.data);
        setInput(getDashboardInput(resp.data));
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  useEffect(() => {
    reduxActions.app.setApp(reduxDispatch, {
      inSubmittingInSavingDashboard: submittedInProgress,
    });
  }, [submittedInProgress]);

  useEffect(() => {
    reduxActions.app.setApp(reduxDispatch, {
      activedDashboard: fetchedData,
    });
  }, [fetchedData]);

  useEffect(() => {
    return () => {
      reduxActions.app.setApp(reduxDispatch, {
        activedDashboard: null,
        inSubmittingInSavingDashboard: false,
      });
    };
  }, []);

  const submitData = async (data: DashboardUpsertInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = pk
        ? `${apiBaseUrl}/dashboard/${pk}`
        : `${apiBaseUrl}/dashboard`;
      const resp = pk
        ? await API.patch<Dashboard>(endpoint, data)
        : await API.post<Dashboard>(endpoint, data);
      const message = `Dashboard successfully ${pk ? 'updated' : 'created'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      if (!isEqual(item, resp.data)) {
        setFetchedData(resp.data);
        setInput(getDashboardInput(resp.data));
      }
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  const updateDashboard = (data: DashboardUpsertInputBody) => {
    // Save dashboard data in background to update panel filters
    if (pk) {
      API.patch<Dashboard>(`${apiBaseUrl}/dashboard/${pk}`, data);
    }
  };

  /*********/
  /* input */
  /*********/
  const [input, setInput] = useState(() => getDashboardInput(fetchedData));
  const [filter, setFilter] = useState(() => getDashboardFilter(input));

  const addDataEntity = (code: PanelCode) => {
    const id = Math.random().toString(16).substring(2, 14);

    setFilter({
      ...filter,
      [id]: {
        chartType: 'bar',
        params: {},
      },
    });

    setInput({
      ...input,
      data: {
        ...input.data,
        entities: [
          ...(input.data?.entities ?? []),
          {
            id,
            type: 'panel',
            name: t(`panels.${code}`),
            panel: {
              code,
              data: {},
            },
            grid: {
              x: 0,
              y: (maxBy(input.data?.entities, 'grid.y')?.grid.y ?? 0) + 1,
              w: 12,
              h: 4,
            },
          },
        ],
      },
    });
    setTimeout(() => {
      const section = document.querySelector(`[data-id="${id}"]`);
      section?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 100);

    return id;
  };

  const handleDrillDown = (action: DashboardDrilldownAction) => {
    const {code} = action;
    const entity = input.data?.entities?.filter(
      (el) => el.panel?.code === code
    )[0];
    const exists = !!entity;

    if (exists) {
      setFilter({
        ...filter,
        [entity.id]: {
          ...filter[entity.id],
          chartType: action.chartType,
          params: {
            ...filter[entity.id].params,
            ...action.filter,
          },
        },
      });

      setTimeout(() => {
        const section = document.querySelector(`[data-id="${entity.id}"]`);
        section?.scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 100);
    } else {
      // add
      const id = addDataEntity(code);

      setFilter({
        ...filter,
        [id]: {
          chartType: action.chartType,
          params: {
            ...action.filter,
          },
        },
      });
    }
  };

  const handleOpenEntityHistory = (
    id: number | string | number[],
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => {
    setTimeout(() => {
      let panelCode: PanelCode = 'CommtracNodeTrackingReports';
      if (['asset', 'employee'].includes(type)) {
        panelCode = 'CommtracNodeTrackingReports';
      } else if (
        ['cn', 'commtracNodeByCn', 'wifi', 'wifiLongTerm'].includes(type)
      ) {
        panelCode = 'NodeTrackingReports';
      } else if (['networkDiagnostics'].includes(type)) {
        panelCode = 'NetworkDiagnostics';
      } else if (['alarm'].includes(type)) {
        panelCode = 'AlarmHistoryReports';
      } else if (['alarm_log'].includes(type)) {
        panelCode = 'AlarmLogReports';
      } else if (['hazard_ai_detection_log'].includes(type)) {
        panelCode = 'HazardAIDectionLog';
      } else if (['hazard_ai_heatmap'].includes(type)) {
        panelCode = 'HazardHeatMapReports';
      }
      const reportedElement = document.querySelector(
        `[data-panel-code="${panelCode}"]`
      );
      reportedElement
        ? reportedElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          })
        : '';
    }, 100);
    if (['asset', 'employee'].includes(type)) {
      const viewType = type === 'asset' ? 'assets' : 'employees';
      const panelCode: PanelCode = 'CommtracNodeTrackingReports';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      if (panelIndex === -1) {
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [
                  ...(input.data?.entities ?? []),
                  {
                    id: Math.random().toString(16).substring(2, 14),
                    type: 'panel',
                    name: t(`panels.${panelCode}`),
                    panel: {
                      code: panelCode,
                      data: {
                        viewType,
                        [viewType]: {
                          activeId: id,
                          openedItems: [{id}],
                        },
                      },
                    },
                    grid: {
                      x: 0,
                      y:
                        (maxBy(input.data?.entities, 'grid.y')?.grid.y ?? 0) +
                        1,
                      w: 12,
                      h: 4,
                    },
                  },
                ],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === id)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: id,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (
      ['cn', 'commtracNodeByCn', 'wifi', 'wifiLongTerm'].includes(type)
    ) {
      const viewType = type;
      const panelCode: PanelCode = 'NodeTrackingReports';
      const panelIndex =
        input?.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      if (panelIndex === -1) {
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [
                  ...(input.data?.entities ?? []),
                  {
                    id: Math.random().toString(16).substring(2, 14),
                    type: 'panel',
                    name: t(`panels.${panelCode}`),
                    panel: {
                      code: panelCode,
                      data: {
                        viewType,
                        [viewType]: {
                          activeId: id,
                          openedItems: [{id}],
                        },
                      },
                    },
                    grid: {
                      x: 0,
                      y:
                        (maxBy(input.data?.entities, 'grid.y')?.grid.y ?? 0) +
                        1,
                      w: 12,
                      h: 4,
                    },
                  },
                ],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === id)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: id,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['networkDiagnostics'].includes(type)) {
      const viewType = 'general';
      const panelCode: PanelCode = 'NetworkDiagnostics';
      const panelIndex =
        input?.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      if (panelIndex === -1) {
        addDataEntity(panelCode);
      } else if (input.data?.entities?.[panelIndex].panel?.data?.[viewType]) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          ...(input.data?.entities?.[panelIndex].panel?.data?.[
                            viewType
                          ] ?? {}),
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['alarm'].includes(type)) {
      const viewType = type;
      const panelCode: PanelCode = 'AlarmHistoryReports';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;

      if (panelIndex === -1) {
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [
                  ...(input.data?.entities ?? []),
                  {
                    id: Math.random().toString(16).substring(2, 14),
                    type: 'panel',
                    name: t(`panels.${panelCode}`),
                    panel: {
                      code: panelCode,
                      data: {
                        viewType,
                        [viewType]: {
                          activeId: id,
                          openedItems: [{id}],
                        },
                      },
                    },
                    grid: {
                      x: 0,
                      y:
                        (maxBy(input.data?.entities, 'grid.y')?.grid.y ?? 0) +
                        1,
                      w: 12,
                      h: 4,
                    },
                  },
                ],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === id)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: id,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['alarm_log'].includes(type)) {
      const viewType = type;
      const panelCode: PanelCode = 'AlarmLogReports';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      if (panelIndex === -1) {
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [
                  ...(input.data?.entities ?? []),
                  {
                    id: Math.random().toString(16).substring(2, 14),
                    type: 'panel',
                    name: t(`panels.${panelCode}`),
                    panel: {
                      code: panelCode,
                      data: {
                        viewType,
                        [viewType]: {
                          activeId: id,
                          openedItems: [{id}],
                        },
                      },
                    },
                    grid: {
                      x: 0,
                      y:
                        (maxBy(input.data?.entities, 'grid.y')?.grid.y ?? 0) +
                        1,
                      w: 12,
                      h: 4,
                    },
                  },
                ],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === id)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: id,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['hazard_ai_detection_log'].includes(type)) {
      const viewType = type;
      const panelCode: PanelCode = 'HazardAIDectionLog';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      if (panelIndex === -1) {
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [
                  ...(input.data?.entities ?? []),
                  {
                    id: Math.random().toString(16).substring(2, 14),
                    type: 'panel',
                    name: t(`panels.${panelCode}`),
                    panel: {
                      code: panelCode,
                      data: {
                        viewType,
                        [viewType]: {
                          activeId: id,
                          openedItems: [{id}],
                        },
                      },
                    },
                    grid: {
                      x: 0,
                      y:
                        (maxBy(input.data?.entities, 'grid.y')?.grid.y ?? 0) +
                        1,
                      w: 12,
                      h: 4,
                    },
                  },
                ],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === id)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: id,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['hazard_ai_heatmap'].includes(type)) {
      const viewType = type;
      const panelCode: PanelCode = 'HazardHeatMapReports';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      const uniqId = (isArray(id) ? [...id] : [id])?.join('-');
      if (panelIndex === -1) {
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [
                  ...(input.data?.entities ?? []),
                  {
                    id: Math.random().toString(16).substring(2, 14),
                    type: 'panel',
                    name: t(`panels.${panelCode}`),
                    panel: {
                      code: panelCode,
                      data: {
                        viewType,
                        [viewType]: {
                          activeId: uniqId,
                          openedItems: [{id: uniqId}],
                        },
                      },
                    },
                    grid: {
                      x: 0,
                      y:
                        (maxBy(input.data?.entities, 'grid.y')?.grid.y ?? 0) +
                        1,
                      w: 12,
                      h: 4,
                    },
                  },
                ],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === uniqId)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: uniqId,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id: uniqId},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    }
  };

  const handleEntityUpdate = (entity: DashboardEntity) => {
    // Megre new panel into the old one to update panel filters
    if (fetchedData?.user_id === me?.id) {
      const entities =
        (input.data?.entities || []).map((e) =>
          e.id === entity.id ? {...e, ...entity} : e
        ) || [];
      updateDashboard({...input, data: {entities}});
    }
  };

  return (
    <DashboardProvider
      value={input}
      onChange={setInput}
      filter={{filter, setFilter}}
      drilldown={handleDrillDown}
    >
      <Box
        position="relative"
        height="100%"
        sx={{
          display: input.data?.entities?.length === 1 ? 'flex' : 'block',
          flexDirection: 'column',
        }}
        id="entity-container"
      >
        <Backdrop
          open={fetchedInProgress}
          sx={{position: 'absolute', zIndex: 1001}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {fetchedErrors.map((error, index) => (
          <Alert key={index} sx={{mb: 2}} severity="error">
            {error}
          </Alert>
        ))}

        {fetchedData && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
                position: 'absolute',
                width: '0px',
                overflow: 'hidden',
              }}
            >
              <Typography component="h1" variant="h5">
                {fetchedData.name}
              </Typography>

              <Box>
                <AccessControl
                  accessChecker={() => fetchedData?.user_id === me?.id}
                >
                  <DashboardPanelSelectButton
                    component={Button}
                    componentProps={{
                      sx: {minWidth: 'auto'},
                      id: 'btn-add-new-item',
                    }}
                    onUpdate={addDataEntity}
                  >
                    <AddIcon />
                  </DashboardPanelSelectButton>
                </AccessControl>

                <AccessControl
                  permissions={['patch::/dashboard/:id(\\d+)']}
                  accessChecker={() => fetchedData?.user_id === me?.id}
                >
                  <LoadingButton
                    sx={{minWidth: 'auto'}}
                    loading={submittedInProgress}
                    id="btn-save-dashboard"
                    onClick={() => submitData(input)}
                  >
                    <SaveIcon />
                  </LoadingButton>
                </AccessControl>
              </Box>
            </Box>

            <DashboardEntityList
              disabled={fetchedData?.user_id !== me?.id}
              entities={input.data?.entities ?? []}
              onUpdate={(v) =>
                setInput(
                  update(input, {
                    data: {
                      entities: {
                        $set: v,
                      },
                    },
                  })
                )
              }
              onEntityUpdate={handleEntityUpdate}
              onOpenHistory={handleOpenEntityHistory}
            />
          </>
        )}
      </Box>
    </DashboardProvider>
  );
};

export default DashboardItem;
