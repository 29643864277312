import {AlarmOutlined, GasMeterOutlined} from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CampaignIcon from '@mui/icons-material/Campaign';
import CellTowerIcon from '@mui/icons-material/CellTower';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import update from 'immutability-helper';
import isEqual from 'lodash/isEqual';
// import {enqueueSnackbar} from 'notistack';
import {useMemo, useState} from 'react';
import {useComponentSize} from 'react-use-size';

// import API from '../../../api/axios';
// import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {AlarmLogNodeListResponse} from '../../../interfaces/AlarmLogNode';
import {AlarmType} from '../../../interfaces/AlarmType';
import {
  BroadcastMessage,
  BroadcastMessageListResponse,
} from '../../../interfaces/BroadcastMessage';
import {
  CommtracNodeListQuery,
  CommtracNodeListResponse,
} from '../../../interfaces/CommtracNode';
import {EventSummaryResponse} from '../../../interfaces/Event';
import {NodeListQuery, NodeListResponse} from '../../../interfaces/Node';
import reduxSelectors from '../../../redux/selectors';
import {getLastSummaryEventDescription} from '../../../utils/events';
import BroadcastMessageClearButton from '../../broadcast-message/buttons/BroadcastMessageClearButton';
import BroadcastMessageUpsertButton from '../../broadcast-message/buttons/BroadcastMessageUpsertButton';
import AccessControl from '../../common/AccessControl';
import TruncatedText from '../../common/TruncantedText';
import SummaryEventButton from '../../event-stat/button/SummaryEventButton';
import {AlarmLogReportData} from './AlarmLogReport';
import {CommtracNodesReportData} from './CommtracNodesReport';
import {NodesReportData} from './NodesReport';

interface Propx {
  activeTab?: ConnectTab;
  commtracNodesTotal?: CommtracNodeListResponse['total'];
  commtracNodesConfig: CommtracNodesReportData;
  nodesTotal?: NodeListResponse['total'];
  nodesConfig: NodesReportData;
  eventsCount?: number;
  broadcastMessagesData?: BroadcastMessageListResponse;
  selectedBroadcastMessageId?: number;
  eventSummaryData?: EventSummaryResponse;
  isNodesLoading?: boolean;
  isCommtracNodesLoading?: boolean;
  isEventsLoading?: boolean;
  isEventSummaryLoading?: boolean;
  isBroadcastMessagesLoading?: boolean;
  isAlarmLogsLoading?: boolean;
  alarmLogsTotal?: AlarmLogNodeListResponse['total'];
  alarmLogsConfig: AlarmLogReportData;
  gasMonitoringConfig: {
    cat: 'sensors' | 'e-modules';
    status: 'all' | 'active' | 'inactive' | 'unack' | 'assigned' | 'unassigned';
  };
  onChangeAlertLogConfig?: (value: AlarmLogReportData) => void;
  onChangeCommtracNodesConfig?: (value: CommtracNodesReportData) => void;
  onChangeGasMonitoringConfig?: (value: {
    cat: 'sensors' | 'e-modules';
    status: 'all' | 'active' | 'inactive' | 'unack' | 'assigned' | 'unassigned';
  }) => void;
  onChangeNodesConfig?: (value: NodesReportData) => void;
  onChangeActiveTab?: (value: ConnectTab) => void;
  onBroadcastMessageCleared?: (item: BroadcastMessage) => void;
  onBroadcastMessageCreated?: (item: BroadcastMessage) => void;
  onChangeSelectedBroadcastMessageId?: (value?: number) => void;
  onEventSummaryFetch?: () => void;
}

export type ConnectTab =
  | 'commtracNodes'
  | 'nodes'
  | 'events'
  | 'alarmLog'
  | 'gasMonitoring';

const boxStatSx: SxProps<Theme> = {
  bgcolor: (theme) =>
    theme.palette.mode === 'dark' ? 'background.default' : 'primary.main',
  color: (theme) => (theme.palette.mode === 'dark' ? 'primary.main' : '#FFF'),
  borderColor: (theme) =>
    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.200',
  height: '100%',
  px: 1,
  py: 2,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

const boxStatActiveSx: SxProps<Theme> = {
  ...boxStatSx,
  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'),
  color: (theme) =>
    theme.palette.mode === 'dark' ? 'primary.main' : 'primary.main',
};

// eslint-disable-next-line complexity
export const ConnectViewStats = ({
  activeTab,
  commtracNodesTotal,
  commtracNodesConfig,
  nodesTotal,
  nodesConfig,
  eventSummaryData,
  eventsCount,
  broadcastMessagesData,
  selectedBroadcastMessageId,
  isNodesLoading,
  isCommtracNodesLoading,
  isEventsLoading,
  isEventSummaryLoading,
  isBroadcastMessagesLoading,
  isAlarmLogsLoading,
  alarmLogsTotal,
  alarmLogsConfig,
  gasMonitoringConfig,
  onChangeAlertLogConfig,
  onChangeActiveTab,
  onChangeSelectedBroadcastMessageId,
  onBroadcastMessageCleared,
  onBroadcastMessageCreated,
  onEventSummaryFetch,
  onChangeCommtracNodesConfig,
  onChangeNodesConfig,
  onChangeGasMonitoringConfig,
}: Propx) => {
  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
  const company = useAppSelector(({assets}) => assets.company);
  const me = useAppSelector(({app}) => app.me);
  const userRole = me?.type_id ?? null;

  const selectedBroadcastMessage = broadcastMessagesData?.items.find(
    (i) => i.id === selectedBroadcastMessageId
  );

  /*****************/
  /* miners status */
  /*****************/

  const [minersMenuAnchorEl, setMinersMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const minersMenuOpen = Boolean(minersMenuAnchorEl);

  const minerStatusOptions: {
    value: CommtracNodeListQuery['miner_status'];
    label: string;
  }[] = useMemo(
    () => [
      {value: 'checked_in', label: 'Checked In'},
      {value: 'checked_out', label: 'Checked Out'},
      {value: 'active', label: 'Active'},
      {value: 'inactive', label: 'Inactive'},
      {value: 'unacknowledged', label: 'Unacknowledged'},
      {value: 'surface', label: 'Surface'},
      {value: 'underground', label: 'Underground'},
      {value: 'all', label: 'All'},
    ],
    []
  );

  const selectedMinerStatusOption = useMemo(
    () =>
      minerStatusOptions.find(
        (i) => i.value === commtracNodesConfig.params.miner_status
      ),
    [commtracNodesConfig.params.miner_status, minerStatusOptions]
  );

  /****************/
  /* nodes status */
  /****************/

  const [nodesMenuAnchorEl, setNodesMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const nodesMenuOpen = Boolean(nodesMenuAnchorEl);

  const nodeStatusOptions: {
    value: NodeListQuery['communication_node_status'];
    label: string;
  }[] = useMemo(
    () => [
      {value: 'all', label: 'All'},
      {value: 'active', label: 'Active'},
      {value: 'inactive', label: 'Inactive'},
      {value: 'connected', label: 'Connected'},
      {value: 'disconnected', label: 'Disconnected'},
      {value: 'unacknowledged', label: 'Unacknowledged'},
    ],
    []
  );

  const selectedNodeStatusOption = useMemo(
    () =>
      nodeStatusOptions.find(
        (i) => i.value === nodesConfig?.params.communication_node_status
      ),
    [nodesConfig?.params.communication_node_status, nodeStatusOptions]
  );

  const nodeTypeOptions: {
    value: NodeListQuery['node_type'][number] | 'all';
    label: string;
  }[] = useMemo(
    () => [
      {value: 'all', label: 'All'},
      {value: 'communication_node', label: 'Communication Node'},
      {value: 'wifi_point', label: 'WiFi Nodes'},
    ],
    []
  );

  const nodeType = useMemo(() => {
    if (isEqual(nodesConfig.params?.node_type, ['communication_node'])) {
      return 'communication_node';
    } else if (isEqual(nodesConfig.params?.node_type, ['wifi_point'])) {
      return 'wifi_point';
    }
    return 'all';
  }, [nodesConfig.params?.node_type]);

  const selectedNodeTypeOption = useMemo(() => {
    if (nodeType === 'communication_node') {
      return nodeTypeOptions.find((i) => i.value === 'communication_node');
    } else if (nodeType === 'wifi_point') {
      return nodeTypeOptions.find((i) => i.value === 'wifi_point');
    }
    return nodeTypeOptions.find((i) => i.value === 'all');
  }, [nodesConfig.params?.node_type, nodeTypeOptions]);

  /*****************/
  /* assets status */
  /*****************/

  const [assetsMenuAnchorEl, setAssetsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const assetsMenuOpen = Boolean(assetsMenuAnchorEl);

  const assetStatusOptions: {
    value: CommtracNodeListQuery['asset_status'];
    label: string;
  }[] = useMemo(
    () => [
      {value: 'checked_in', label: 'Checked In'},
      {value: 'checked_out', label: 'Checked Out'},
      {value: 'active', label: 'Active'},
      {value: 'inactive', label: 'Inactive'},
      {value: 'unacknowledged', label: 'Unacknowledged'},
      {value: 'surface', label: 'Surface'},
      {value: 'underground', label: 'Underground'},
      {value: 'all', label: 'All'},
    ],
    []
  );

  const selectedAssetStatusOption = useMemo(
    () =>
      assetStatusOptions.find(
        (i) => i.value === commtracNodesConfig.params.asset_status
      ),
    [commtracNodesConfig.params.asset_status, assetStatusOptions]
  );

  /****************/
  /* event status */
  /****************/

  const lastSummaryEventDescription = useMemo(() => {
    return (
      getLastSummaryEventDescription(eventSummaryData?.connect) ?? 'Events'
    );
  }, [eventSummaryData?.connect]);

  /*********************/
  /* broadcast message */
  /*********************/

  const [broadcastMessageMenuAnchorEl, setBroadcastMessageMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const broadcastMessageMenuOpen = Boolean(broadcastMessageMenuAnchorEl);

  /*********/
  /* sizes */
  /*********/

  const {ref, width} = useComponentSize();
  const iconFontSize = 46;
  const numberFontSize = useMemo(() => {
    if (width < 1700) {
      return 40;
    }
    return 60;
  }, [width]);

  /**
   * Alarm Log
   */

  const [alarmStatusMenuAnchorEl, setAlarmStatusMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  // const [alarmAcknowledgeMenuAnchorEl, setAlarmAcknowledgeMenuAnchorEl] =
  //   useState<null | HTMLElement>(null);
  const alarmStatusMenuOpen = Boolean(alarmStatusMenuAnchorEl);
  // const alarmAcknowledgeMenuOpen = Boolean(alarmAcknowledgeMenuAnchorEl);

  const alarmStatusOptions: {
    value: AlarmType['status'] | 'all' | 'unacknowledged';
    label: string;
  }[] = useMemo(
    () => [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'unacknowledged',
        label: 'Unacknowledged',
      },
    ],
    []
  );

  const selectedAlarmStatusOption = useMemo(
    () =>
      alarmStatusOptions.find((i) => i.value === alarmLogsConfig.params.status),
    [alarmLogsConfig.params.status, alarmStatusOptions]
  );

  // const submitButtonPressAcknowledge = async (message_id: number) => {
  //   const payload = {message_id, silent: true};
  //   try {
  //     const resp = await API.patch(
  //       `${apiBaseUrl}/alarm-module/alarm/enable`,
  //       payload
  //     );
  //     onEventSummaryFetch?.();

  //     enqueueSnackbar(resp.data.message, {
  //       variant: 'success',
  //       action: CloseSnackbarAction,
  //     });
  //   } catch (error: any) {
  //     const message = error?.response?.data?.message ?? 'There is an error';
  //     enqueueSnackbar(message, {
  //       variant: 'error',
  //       action: CloseSnackbarAction,
  //     });
  //   }
  // };

  // const acknowledgeButtonPress = (id: number, silent?: boolean) => {
  //   if (silent) {
  //     submitButtonPressAcknowledge(id);
  //   } else {
  //     onChangeAlertLogConfig?.(
  //       update(alarmLogsConfig, {
  //         enableAlarm: {open: {$set: true}, message_id: {$set: id}},
  //       })
  //     );
  //   }
  // };

  /*************************/
  /* gas monitoring status */
  /*************************/

  const [gasCatMenuAnchorEl, setGasCatMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const gasCatMenuOpen = Boolean(gasCatMenuAnchorEl);

  const gasCatOptions: {
    value: 'sensors' | 'e-modules';
    label: string;
  }[] = useMemo(
    () =>
      userRole === 1 || userRole === 2 || userRole === 3
        ? [
            {
              value: 'sensors',
              label: 'Sensors',
            },
            {
              value: 'e-modules',
              label: 'e-Modules',
            },
          ]
        : [
            {
              value: 'sensors',
              label: 'Sensors',
            },
          ],
    [userRole]
  );

  const gasStatusOptions: {
    value: 'all' | 'active' | 'inactive' | 'unack' | 'assigned' | 'unassigned';
    label: string;
    enable: boolean;
  }[] = useMemo(
    () => [
      {
        value: 'all',
        label: 'All',
        enable: true,
      },
      {
        value: 'active',
        label: 'Active',
        enable: true,
      },
      {
        value: 'inactive',
        label: 'Inactive',
        enable: true,
      },
      {
        value: 'unack',
        label: 'Unacknowledged',
        enable: gasMonitoringConfig.cat === 'sensors',
      },
      {
        value: 'assigned',
        label: 'Assigned',
        enable: gasMonitoringConfig.cat === 'e-modules',
      },
      {
        value: 'unassigned',
        label: 'Unassigned',
        enable: gasMonitoringConfig.cat === 'e-modules',
      },
    ],
    [gasMonitoringConfig.cat]
  );

  const selectedGasCatOption = useMemo(
    () => gasCatOptions.find((i) => i.value === gasMonitoringConfig.cat),
    [gasMonitoringConfig.cat, gasCatOptions]
  );

  const selectedGasStatusOption = useMemo(
    () => gasStatusOptions.find((i) => i.value === gasMonitoringConfig.status),
    [gasMonitoringConfig.status, gasStatusOptions]
  );

  return (
    <Box ref={ref} sx={{overflowX: 'auto'}}>
      <Grid container minWidth={1200}>
        {/******* Start Nodes Tab *******/}
        <Grid item xs={company?.ams_enabled ? 12 / 7 : 2} position="relative">
          <Backdrop open={!!isNodesLoading} sx={{position: 'absolute'}} />
          <Box
            sx={{
              ...(activeTab === 'nodes' ? boxStatActiveSx : boxStatSx),
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
              cursor: 'pointer',
            }}
            onClick={() => onChangeActiveTab?.('nodes')}
          >
            <CellTowerIcon sx={{fontSize: iconFontSize}} />

            <Box
              display="flex"
              alignItems="center"
              gap={1}
              fontSize={numberFontSize}
              height="100%"
            >
              {nodesTotal ? (
                <>
                  {nodeType === 'communication_node' ? (
                    <Box>{nodesTotal.filter.communication_node}</Box>
                  ) : nodeType === 'wifi_point' ? (
                    <Box>{nodesTotal.filter.wifi_point}</Box>
                  ) : (
                    <Box>
                      {nodesTotal.filter.communication_node +
                        nodesTotal.filter.wifi_point}
                    </Box>
                  )}
                  <Box>/</Box>
                  <Box>
                    {nodeType === 'communication_node'
                      ? nodesTotal.all.communication_node
                      : nodeType === 'wifi_point'
                        ? nodesTotal.all.wifi_point
                        : nodesTotal.all.communication_node +
                          nodesTotal.all.wifi_point}
                  </Box>
                </>
              ) : (
                <HourglassEmptyIcon fontSize="large" />
              )}
            </Box>

            <Button
              variant="text"
              color="inherit"
              sx={{width: '100%'}}
              endIcon={<ArrowDropDownIcon />}
              onClick={(event) => setNodesMenuAnchorEl(event.currentTarget)}
            >
              <TruncatedText>
                Nodes {selectedNodeStatusOption?.label} (
                {selectedNodeTypeOption?.label})
              </TruncatedText>
            </Button>

            <Menu
              anchorEl={nodesMenuAnchorEl}
              open={nodesMenuOpen}
              sx={{zIndex: 10000}}
              onBackdropClick={() => setNodesMenuAnchorEl(null)}
            >
              <Typography
                textTransform="uppercase"
                fontWeight={400}
                color="text.disabled"
                p={2}
                pb={1}
              >
                Nodes
              </Typography>

              {nodeTypeOptions.map((i) => (
                <MenuItem
                  key={i.value}
                  selected={i.value === nodeType}
                  onClick={() => {
                    const nodeType = i.value === 'all' ? [] : [i.value];
                    onChangeNodesConfig?.(
                      update(nodesConfig, {
                        params: {
                          node_type: {
                            $set: nodeType,
                          },
                        },
                        grid: {
                          page: {
                            $set: 0,
                          },
                        },
                      })
                    );
                    setNodesMenuAnchorEl(null);
                  }}
                >
                  {i.label}
                </MenuItem>
              ))}

              <Divider />

              <Typography
                textTransform="uppercase"
                fontWeight={400}
                color="text.disabled"
                p={2}
                pb={1}
              >
                Status
              </Typography>

              {nodeStatusOptions.map((i) => (
                <MenuItem
                  key={i.value}
                  selected={
                    i.value === nodesConfig.params.communication_node_status
                  }
                  onClick={() => {
                    onChangeNodesConfig?.(
                      update(nodesConfig, {
                        params: {
                          communication_node_status: {
                            $set: i.value,
                          },
                          wifi_point_status: {
                            $set: i.value,
                          },
                        },
                        grid: {
                          page: {
                            $set: 0,
                          },
                        },
                      })
                    );
                    setNodesMenuAnchorEl(null);
                  }}
                >
                  {i.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Grid>
        {/******* End Nodes Tab *******/}

        {/******* Start Employees Tab *******/}
        <Grid item xs={company?.ams_enabled ? 12 / 7 : 2} position="relative">
          <Backdrop
            open={!!isCommtracNodesLoading}
            sx={{position: 'absolute'}}
          />
          <Box
            sx={{
              ...(activeTab === 'commtracNodes' ? boxStatActiveSx : boxStatSx),
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
              cursor: 'pointer',
            }}
            onClick={() => onChangeActiveTab?.('commtracNodes')}
          >
            <PersonIcon sx={{fontSize: iconFontSize}} />

            <Box
              display="flex"
              alignItems="center"
              gap={1}
              fontSize={numberFontSize}
              height="100%"
            >
              {commtracNodesTotal ? (
                <>
                  <Box>{commtracNodesTotal?.filter.miner}</Box>
                  <Box>/</Box>
                  <Box>{commtracNodesTotal?.all.miner}</Box>
                </>
              ) : (
                <HourglassEmptyIcon fontSize="large" />
              )}
            </Box>

            <Button
              variant="text"
              color="inherit"
              sx={{width: '100%'}}
              endIcon={<ArrowDropDownIcon />}
              onClick={(event) => setMinersMenuAnchorEl(event.currentTarget)}
            >
              <TruncatedText>
                Employees {selectedMinerStatusOption?.label}
              </TruncatedText>
            </Button>

            <Menu
              anchorEl={minersMenuAnchorEl}
              open={minersMenuOpen}
              sx={{zIndex: 10000}}
              onBackdropClick={() => setMinersMenuAnchorEl(null)}
            >
              {minerStatusOptions.map((i) => (
                <MenuItem
                  key={i.value}
                  selected={i.value === commtracNodesConfig.params.miner_status}
                  onClick={() => {
                    onChangeCommtracNodesConfig?.(
                      update(commtracNodesConfig, {
                        params: {
                          miner_status: {
                            $set: i.value,
                          },
                        },
                        grid: {
                          page: {
                            $set: 0,
                          },
                        },
                      })
                    );
                    setMinersMenuAnchorEl(null);
                  }}
                >
                  {i.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Grid>
        {/******* End Employees Tab *******/}

        {/******* Start Assets Tab *******/}
        <Grid item xs={company?.ams_enabled ? 12 / 7 : 2} position="relative">
          <Backdrop
            open={!!isCommtracNodesLoading}
            sx={{position: 'absolute'}}
          />
          <Box
            sx={{
              ...(activeTab === 'commtracNodes' ? boxStatActiveSx : boxStatSx),
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
              cursor: 'pointer',
            }}
            onClick={() => onChangeActiveTab?.('commtracNodes')}
          >
            <LocalShippingIcon sx={{fontSize: iconFontSize}} />

            <Box
              display="flex"
              alignItems="center"
              gap={1}
              fontSize={numberFontSize}
              height="100%"
            >
              {commtracNodesTotal ? (
                <>
                  <Box>{commtracNodesTotal?.filter.asset}</Box>
                  <Box>/</Box>
                  <Box>{commtracNodesTotal?.all.asset}</Box>
                </>
              ) : (
                <HourglassEmptyIcon fontSize="large" />
              )}
            </Box>

            <Button
              variant="text"
              color="inherit"
              sx={{width: '100%'}}
              endIcon={<ArrowDropDownIcon />}
              onClick={(event) => setAssetsMenuAnchorEl(event.currentTarget)}
            >
              <TruncatedText>
                Assets {selectedAssetStatusOption?.label}
              </TruncatedText>
            </Button>

            <Menu
              anchorEl={assetsMenuAnchorEl}
              open={assetsMenuOpen}
              sx={{zIndex: 10000}}
              onBackdropClick={() => setAssetsMenuAnchorEl(null)}
            >
              {assetStatusOptions.map((i) => (
                <MenuItem
                  key={i.value}
                  selected={i.value === commtracNodesConfig.params.asset_status}
                  onClick={() => {
                    onChangeCommtracNodesConfig?.(
                      update(commtracNodesConfig, {
                        params: {
                          asset_status: {
                            $set: i.value,
                          },
                        },
                        grid: {
                          page: {
                            $set: 0,
                          },
                        },
                      })
                    );
                    setAssetsMenuAnchorEl(null);
                  }}
                >
                  {i.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Grid>
        {/******* End Assets Tab *******/}

        {/******* Start AMS Tab (For only AMS Enabled) *******/}
        {company?.ams_enabled && (
          <Grid item xs={12 / 7} position="relative">
            <Backdrop open={false} sx={{position: 'absolute'}} />
            <Box
              sx={{
                ...(activeTab === 'gasMonitoring'
                  ? boxStatActiveSx
                  : boxStatSx),
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                cursor: 'pointer',
              }}
              onClick={() => onChangeActiveTab?.('gasMonitoring')}
            >
              <GasMeterOutlined sx={{fontSize: iconFontSize}} />

              <Box
                display="flex"
                alignItems="center"
                textAlign="center"
                gap={1}
                fontSize="20px"
                lineHeight="100%"
                height="100%"
              >
                GAS <br /> Monitoring
              </Box>
              <Box display="flex" width="100%">
                <Button
                  variant="text"
                  color="inherit"
                  sx={{width: '100%'}}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(event) =>
                    setGasCatMenuAnchorEl(event.currentTarget)
                  }
                >
                  <TruncatedText>
                    {selectedGasCatOption?.label}{' '}
                    {selectedGasStatusOption?.label}
                  </TruncatedText>
                </Button>
              </Box>

              <Menu
                anchorEl={gasCatMenuAnchorEl}
                open={gasCatMenuOpen}
                sx={{zIndex: 10000, width: '100%'}}
                onBackdropClick={() => setGasCatMenuAnchorEl(null)}
              >
                {gasCatOptions.map((i) => (
                  <MenuItem
                    key={i.value}
                    selected={i.value === gasMonitoringConfig.cat}
                    onClick={() => {
                      onChangeGasMonitoringConfig?.(
                        update(gasMonitoringConfig, {
                          cat: {
                            $set: i.value,
                          },
                          status: {
                            $set: 'all',
                          },
                        })
                      );
                      setGasCatMenuAnchorEl(null);
                    }}
                  >
                    {i.label}
                  </MenuItem>
                ))}
                <Divider />

                <Typography
                  textTransform="uppercase"
                  fontWeight={400}
                  color="text.disabled"
                  p={2}
                  pb={1}
                >
                  Status
                </Typography>
                {gasStatusOptions
                  .filter((i) => !!i.enable)
                  .map((i) => (
                    <MenuItem
                      key={i.value}
                      selected={i.value === gasMonitoringConfig.status}
                      onClick={() => {
                        onChangeGasMonitoringConfig?.(
                          update(gasMonitoringConfig, {
                            status: {
                              $set: i.value,
                            },
                          })
                        );
                        setGasCatMenuAnchorEl(null);
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
          </Grid>
        )}
        {/******* End AMS Tab *******/}

        {/******* Start Alarms Tab *******/}
        <Grid item xs={company?.ams_enabled ? 12 / 7 : 2} position="relative">
          <Backdrop open={!!isAlarmLogsLoading} sx={{position: 'absolute'}} />
          <Box
            sx={{
              ...(activeTab === 'alarmLog' ? boxStatActiveSx : boxStatSx),
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
              cursor: 'pointer',
            }}
            onClick={() => onChangeActiveTab?.('alarmLog')}
          >
            <AlarmOutlined
              color={eventSummaryData?.alarm?.count ? 'error' : 'inherit'}
              sx={{fontSize: iconFontSize}}
            />
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              fontSize={numberFontSize}
              height="100%"
            >
              {alarmLogsTotal ? (
                <>
                  <Box>{alarmLogsTotal?.activated || 0}</Box>
                  <Box>/</Box>
                  <Box>{alarmLogsTotal?.filtered || 0}</Box>
                </>
              ) : (
                <HourglassEmptyIcon fontSize="large" />
              )}
            </Box>
            <Box display="flex" width="100%" alignItems="justify">
              <Button
                variant="text"
                color="inherit"
                sx={{width: '100%'}}
                endIcon={<ArrowDropDownIcon />}
                onClick={(event) =>
                  setAlarmStatusMenuAnchorEl(event.currentTarget)
                }
              >
                <TruncatedText>
                  {selectedAlarmStatusOption?.label}
                </TruncatedText>
              </Button>
              {/* <AccessControl
                permissions={['patch::/alarm-module/alarm/enable']}
              >
                {eventSummaryData?.alarm?.count ? (
                  <Button
                    variant={isDarkMode ? 'text' : 'contained'}
                    color="error"
                    sx={{width: '100%', color: 'error'}}
                    endIcon={<ArrowDropDownIcon />}
                    onClick={(event) =>
                      setAlarmAcknowledgeMenuAnchorEl(event.currentTarget)
                    }
                  >
                    <TruncatedText>{`Ack (${eventSummaryData?.alarm?.count})`}</TruncatedText>
                  </Button>
                ) : null}
              </AccessControl> */}
            </Box>
            <Menu
              anchorEl={alarmStatusMenuAnchorEl}
              open={alarmStatusMenuOpen}
              sx={{zIndex: 10000}}
              onBackdropClick={() => setAlarmStatusMenuAnchorEl(null)}
            >
              {alarmStatusOptions.map((i) => (
                <MenuItem
                  key={i.value}
                  selected={i.value === alarmLogsConfig.params.status}
                  onClick={() => {
                    onChangeAlertLogConfig?.(
                      update(alarmLogsConfig, {
                        params: {
                          status: {
                            $set: i.value,
                          },
                        },
                        grid: {
                          page: {
                            $set: 0,
                          },
                        },
                      })
                    );
                    setAlarmStatusMenuAnchorEl(null);
                  }}
                >
                  {i.label}
                </MenuItem>
              ))}
            </Menu>
            {/* {eventSummaryData?.alarm?.unacked?.length ? (
              <AccessControl
                permissions={['patch::/alarm-module/alarm/enable']}
              >
                <Menu
                  anchorEl={alarmAcknowledgeMenuAnchorEl}
                  open={alarmAcknowledgeMenuOpen}
                  sx={{zIndex: 10000}}
                  onBackdropClick={() => setAlarmAcknowledgeMenuAnchorEl(null)}
                >
                  {[eventSummaryData?.alarm?.unacked?.[0]].map(
                    (i: EventSummaryResent) => [
                      <MenuItem
                        key={i?.id}
                        onClick={() => {
                          i && acknowledgeButtonPress(i.id);
                          setAlarmAcknowledgeMenuAnchorEl(null);
                        }}
                      >
                        {`Ack button press on '${tweakAlarmNameWithMacAddress(
                          i?.name ?? '',
                          i?.commtrac_external_id
                            ? getHumanReadable(i?.commtrac_external_id ?? '') ??
                                ''
                            : ''
                        )}'`}
                      </MenuItem>,
                      <MenuItem
                        sx={{color: 'grey'}}
                        key={i?.id ?? 0 + 1}
                        onClick={() => {
                          i && acknowledgeButtonPress(i.id, true);
                          setAlarmAcknowledgeMenuAnchorEl(null);
                        }}
                      >
                        {`Ack button press silently on '${tweakAlarmNameWithMacAddress(
                          i?.name ?? '',
                          i?.commtrac_external_id
                            ? getHumanReadable(i?.commtrac_external_id ?? '') ??
                                ''
                            : ''
                        )}'`}
                      </MenuItem>,
                    ]
                  )}
                </Menu>
              </AccessControl>
            ) : null} */}
          </Box>
        </Grid>
        {/******* End Alarms Tab *******/}

        {/******* Start Events Tab *******/}
        <Grid item xs={company?.ams_enabled ? 12 / 7 : 2} position="relative">
          <Backdrop
            open={!!isEventSummaryLoading || !!isEventsLoading}
            sx={{position: 'absolute'}}
          />
          <Box
            overflow="hidden"
            sx={{
              ...(activeTab === 'events' ? boxStatActiveSx : boxStatSx),
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
              cursor: 'pointer',
            }}
            onClick={() => onChangeActiveTab?.('events')}
          >
            <CampaignIcon
              sx={{
                fontSize: iconFontSize,
                color: (theme) =>
                  eventsCount || eventSummaryData?.connect?.count
                    ? theme.palette.error.main
                    : undefined,
              }}
            />

            <Box
              display="flex"
              alignItems="center"
              gap={1}
              fontSize={numberFontSize}
              height="100%"
              whiteSpace="nowrap"
              color={(theme) =>
                eventsCount || eventSummaryData?.connect?.count
                  ? theme.palette.error.main
                  : undefined
              }
            >
              {eventSummaryData?.connect?.unacked ?? (
                <HourglassEmptyIcon fontSize="large" />
              )}{' '}
              /{' '}
              {eventSummaryData?.connect?.count ?? (
                <HourglassEmptyIcon fontSize="large" />
              )}
            </Box>

            {eventSummaryData?.connect?.unacked ? (
              <SummaryEventButton
                product={eventSummaryData?.connect}
                eventDescription={lastSummaryEventDescription}
                componentProps={{
                  variant: !isDarkMode ? 'contained' : 'text',
                  endIcon: <ArrowDropDownIcon />,
                  color: 'error',
                  sx: {width: '100%'},
                }}
                onDone={onEventSummaryFetch}
              >
                <TruncatedText>{`1 of ${eventSummaryData?.connect?.unacked}: ${lastSummaryEventDescription}`}</TruncatedText>
              </SummaryEventButton>
            ) : (
              <TruncatedText padding={1}>
                No unacknowledged events
              </TruncatedText>
            )}
          </Box>
        </Grid>
        {/******* End Events Tab *******/}

        {/******* Start Emergency Broadcast Tab *******/}
        <Grid item xs={company?.ams_enabled ? 12 / 7 : 2} position="relative">
          <Backdrop
            open={!!isBroadcastMessagesLoading}
            sx={{position: 'absolute'}}
          />
          <Box sx={boxStatSx}>
            <WarningIcon
              color={broadcastMessagesData?.count ? 'error' : 'inherit'}
              sx={{fontSize: iconFontSize}}
            />
            <AccessControl
              permissions={['post::/commtrac-broadcast-message']}
              fallback={
                <Box
                  color={
                    broadcastMessagesData?.count ? 'error.main' : 'inherit'
                  }
                  fontSize={numberFontSize}
                >
                  {broadcastMessagesData?.count ?? (
                    <HourglassEmptyIcon fontSize="large" />
                  )}
                </Box>
              }
            >
              <BroadcastMessageUpsertButton
                component={Box}
                componentProps={{
                  color: broadcastMessagesData?.count
                    ? 'error.main'
                    : 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontSize: numberFontSize,
                  height: '48%',
                  whiteSpace: 'nowrap',
                  sx: {
                    cursor: 'pointer',
                  },
                }}
                onSubmitted={(item) => onBroadcastMessageCreated?.(item)}
              >
                {broadcastMessagesData?.count ?? (
                  <HourglassEmptyIcon fontSize="large" />
                )}
              </BroadcastMessageUpsertButton>
            </AccessControl>
            <Box
              display="flex"
              alignItems="center"
              maxWidth="100%"
              overflow="hidden"
            >
              <AccessControl permissions={['get::/commtrac-broadcast-message']}>
                <Button
                  variant={
                    broadcastMessagesData?.count && !isDarkMode
                      ? 'contained'
                      : 'text'
                  }
                  endIcon={
                    broadcastMessagesData?.count ? <ArrowDropDownIcon /> : null
                  }
                  disabled={!broadcastMessagesData?.count}
                  color={broadcastMessagesData?.count ? 'error' : 'inherit'}
                  sx={{width: '100%'}}
                  onClick={(event) =>
                    setBroadcastMessageMenuAnchorEl(event.currentTarget)
                  }
                >
                  <TruncatedText>
                    {selectedBroadcastMessage?.text ?? 'Emergency Broadcast'}
                  </TruncatedText>
                </Button>
                <Menu
                  anchorEl={broadcastMessageMenuAnchorEl}
                  open={broadcastMessageMenuOpen}
                  sx={{zIndex: 10000}}
                  MenuListProps={{sx: {minWidth: 200}}}
                  onBackdropClick={() => setBroadcastMessageMenuAnchorEl(null)}
                >
                  {broadcastMessagesData?.items.map((i) => (
                    <MenuItem
                      key={i.id}
                      selected={i.id === selectedBroadcastMessage?.id}
                      onClick={() => {
                        onChangeSelectedBroadcastMessageId?.(i.id);
                        setBroadcastMessageMenuAnchorEl(null);
                      }}
                    >
                      {i.text}
                    </MenuItem>
                  ))}
                </Menu>
              </AccessControl>
              <Box
                display="flex"
                flexGrow={1}
                justifyContent="flex-end"
                gap={1}
              >
                {selectedBroadcastMessage ? (
                  <AccessControl
                    permissions={[
                      'patch::/commtrac-broadcast-message/:id/clear',
                    ]}
                  >
                    <BroadcastMessageClearButton
                      pk={selectedBroadcastMessage?.id}
                      component={IconButton}
                      componentProps={{
                        color: 'error',
                      }}
                      onDone={() =>
                        onBroadcastMessageCleared?.(selectedBroadcastMessage)
                      }
                    >
                      <ClearIcon />
                    </BroadcastMessageClearButton>
                  </AccessControl>
                ) : null}
                <AccessControl
                  permissions={['post::/commtrac-broadcast-message']}
                >
                  <BroadcastMessageUpsertButton
                    component={IconButton}
                    componentProps={{
                      color: 'inherit',
                    }}
                    onSubmitted={(item) => onBroadcastMessageCreated?.(item)}
                  >
                    <SendIcon />
                  </BroadcastMessageUpsertButton>
                </AccessControl>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/******* End Emergency Broadcast Tab *******/}
      </Grid>
    </Box>
  );
};
