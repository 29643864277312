import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useGetAssetHumanType} from '../../hooks/get-commtrac-node-type';
import {useAppSelector} from '../../hooks/redux';
import {
  AssetHuman,
  AssetHumanListQuery,
  AssetHumanListResponse,
} from '../../interfaces/AssetHumanAdmin';
import reduxSelectors from '../../redux/selectors';
import {getHumanReadable} from '../../utils/macAddress';
import AccessControl from '../common/AccessControl';
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';
import AssetHumanItemEditButton from './AssetHumanItemEditButton';

interface Props {}

export interface AssetHumanListRef {
  fetch?: () => void;
  dataGridRef: MutableRefObject<DataGridRef | null>;
}

const AssetHumanList = forwardRef<AssetHumanListRef, Props>((__, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(formik.values),
    dataGridRef,
  }));

  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
  const paperBg = isDarkMode ? '#222222' : '#FFF';
  const zones = useAppSelector(({assets}) => assets.zones);
  const shifts = useAppSelector(({assets}) => assets.shifts);
  const productsEnabled = useAppSelector(reduxSelectors.assets.productsEnabled);
  const assetHumanTypes = useAppSelector(
    ({assets}) => assets.asset_human_types
  );
  const minerAddressMask = useAppSelector(
    ({assets}) => assets.constants?.miner.address_mask
  );

  const statusOptions: {
    value: AssetHumanListQuery['status'];
    label: string;
  }[] = [
    {value: 'all', label: 'All'},
    {value: 'active', label: 'Active'},
    {value: 'inactive', label: 'Inactive'},
  ];

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<AssetHumanListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const getAssetHumanType = useGetAssetHumanType();

  const fetchData = async (params: AssetHumanListQuery) => {
    setFetchedInProgress(true);
    try {
      const resp = await API.get<AssetHumanListResponse>(
        `${apiBaseUrl}/asset-human`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<AssetHuman>[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      sxHeader: {textAlign: 'center'},
      sxCell: {textAlign: 'center'},
      renderCell: ({row}) => {
        console.log(row)
        return (
          <Box display="flex" gap={1} justifyContent="end">
            <AccessControl permissions={['patch::/asset-human/:id']}>
              <AssetHumanItemEditButton
                pk={row.id}
                item={row}
                prefetch
                component={IconButton}
                componentProps={{color: 'primary', size: 'small'}}
                onSubmitted={() => fetchData(formik.values)}
                mode="update"
              >
                <MoreHorizIcon />
              </AssetHumanItemEditButton>
            </AccessControl>
          </Box>
        );
      },
    },
    {
      field: 'mc2_flag',
      headerName: 'MC2',
      hidden: !productsEnabled.includes('connect'),
      valueGetter: ({row}) =>
        row.commtrac_node ? (row.commtrac_node?.mc2_flag ? 'Yes' : 'No') : '',
    },
    {
      field: 'personal_id',
      headerName: 'Employee ID',
      sortable: true,
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      hidden: !productsEnabled.includes('connect'),
      valueGetter: ({row}) => {
        if (row.commtrac_external_id && minerAddressMask) {
          // eslint-disable-next-line no-bitwise
          return row.commtrac_external_id & minerAddressMask;
        }
      },
    },
    {
      field: 'mac_address',
      headerName: 'MAC-address',
      sortable: true,
      hidden: !productsEnabled.includes('connect'),
      valueGetter: ({row}) => {
        if (row.mac_address) {
          return getHumanReadable(row.mac_address);
        }
      },
    },
    {
      field: 'external_id',
      headerName: 'Proximity ID',
      sortable: true,
      hidden: !productsEnabled.includes('proximity'),
    },
    {
      field: 'nickname',
      headerName: 'Nickname',
      sortable: true,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      sortable: true,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      sortable: true,
    },
    {
      field: 'birth_date',
      headerName: 'Birthday',
      sortable: true,
    },
    {
      field: 'type_id',
      headerName: 'Type',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.commtrac_node) {
          return getAssetHumanType({
            commtrac_external_id: row.commtrac_node?.commtrac_external_id,
            wifi_enabled: row.commtrac_node?.wifi_enabled,
            atest: row.commtrac_node?.atest,
            mc2_flag: row.commtrac_node?.mc2_flag,
            sensors_number: row.commtrac_node?.sensors_number,
          });
        } else {
          return null;
        }
      },
    },
    {
      field: 'type_id',
      headerName: 'Job Type',
      sortable: true,
      valueGetter: ({row}) =>
        assetHumanTypes.find((i) => i.id === row.type_id)?.name,
    },
    {
      field: 'zone_id',
      headerName: 'Section',
      sortable: true,
      valueGetter: ({row}) => zones.find((i) => i.id === row.zone_id)?.name,
    },
    {
      field: 'shift_id',
      headerName: 'Shift',
      sortable: true,
      valueGetter: ({row}) => shifts.find((i) => i.id === row.shift_id)?.name,
    },
    {
      field: 'status',
      sortable: true,
      renderCell: ({row}) => {
        return row.status === 'active' ? (
          <ToggleOnIcon color="success" />
        ) : (
          <ToggleOffIcon color="error" />
        );
      },
    },
    {
      field: 'commtrac_report_frequency',
      headerName: 'Reporting Frequency',
      hidden: !productsEnabled.includes('connect'),
      valueGetter: ({row}) => row.commtrac_node?.commtrac_report_frequency,
    },
    {
      field: 'cam_monitor_battery',
      headerName: 'Monitor Battery',
      hidden: !productsEnabled.includes('connect'),
      valueGetter: ({row}) => {
        if (row.commtrac_node) {
          return getAssetHumanType({
            commtrac_external_id: row.commtrac_node?.cam_monitor_battery,
            wifi_enabled: row.commtrac_node?.wifi_enabled,
            atest: row.commtrac_node?.atest,
            mc2_flag: row.commtrac_node?.mc2_flag,
            sensors_number: row.commtrac_node?.sensors_number,
          }) === 'CAM' && !row.commtrac_node?.cam_monitor_battery
            ? 'No'
            : 'Yes';
        } else {
          return null;
        }
      },
    },
    {
      field: 'has_motion_sensor',
      headerName: 'Motion Sensor',
      hidden: !productsEnabled.includes('connect'),
      valueGetter: ({row}) =>
        row.commtrac_node?.has_motion_sensor
          ? row.commtrac_node?.has_motion_sensor
            ? 'Yes'
            : 'No'
          : '',
    },
    {
      field: 'date_activated',
      headerName: 'Activated Timestamp',
      sortable: true,
    },
    {
      field: 'date_deactivated',
      headerName: 'Deactivated Timestamp',
      sortable: true,
    },
    {
      field: 'last_update',
      headerName: 'Last Seen',
      sortable: true,
      hidden: !productsEnabled.includes('connect'),
    },
  ];

  const formik = useFormik<AssetHumanListQuery>({
    initialValues: {
      page: 0,
      limit: 25,
      order: 'id',
      dir: 'ASC',
      status: 'all',
      filter: null,
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    fetchData(formik.values);
  }, [formik.values]);

  return (
    <Paper
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        p: 3,
        bgcolor: paperBg,
        backgroundImage: 'none',
      }}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" height="100%">
        <Box pb={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="left"
            height={40}
            width={200}
            gap={2}
          >
            <Box flexGrow={1} display="flex" width="200px" height="100%">
              <TextField
                fullWidth
                label="Status"
                select
                SelectProps={{
                  multiple: false,
                }}
                value={formik.values.status}
                onChange={(el) => {
                  formik.setFieldValue('status', el.target.value);

                  if (el.target.value === 'all') {
                    formik.setFieldValue('filter', null);
                  } else {
                    formik.setFieldValue(
                      'filter',
                      JSON.stringify({
                        type: 'and',
                        filter: [
                          {
                            field: 'status',
                            op: 'in',
                            val: [el.target.value],
                          },
                        ],
                      })
                    );
                  }
                }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value ?? ''} value={option.value ?? ''}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </Box>
        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          loading={fetchedInProgress}
          pagination
          paginationMode="server"
          size="small"
          sx={{
            backgroundColor: 'unset',
            th: {
              bgcolor: paperBg,
            },
          }}
          sortBy={{
            field: formik.values.order,
            dir: formik.values.dir === 'DESC' ? 'desc' : 'asc',
          }}
          sortingMode="server"
          page={formik.values.page}
          pageSize={formik.values.limit}
          rowCount={fetchedData?.count}
          onPageChange={(v) => formik.setFieldValue('page', v)}
          onPageSizeChange={(v) => {
            formik.setFieldValue('limit', v);
            formik.setFieldValue('page', 0);
          }}
          onSort={(v) => {
            if (v) {
              formik.setFieldValue('order', v.field);
              formik.setFieldValue('dir', v.dir.toUpperCase());
            }
          }}
        />
      </Box>
    </Paper>
  );
});

export default AssetHumanList;
