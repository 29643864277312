import {ZoomInMapTwoTone} from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import {
  Backdrop,
  Box,
  BoxProps,
  Button,
  Divider,
  Menu,
  MenuItem,
  Tooltip as MuiTooltip,
  Typography,
} from '@mui/material';
import {Icon, LatLng, LatLngBounds, latLngBounds} from 'leaflet';
import {MousePosition} from 'leaflet.mouseposition.ts/dist/src';
import _, {uniqueId} from 'lodash';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  Tooltip,
  useMapEvents,
} from 'react-leaflet';
import {useSelector} from 'react-redux';
import {useComponentSize} from 'react-use-size';

import {mineMapBaseUrl} from '../../../api/urls';
import {useConfigurations} from '../../../hooks/configuration';
import {AlarmLogNode} from '../../../interfaces/AlarmLogNode';
import {CommtracNode} from '../../../interfaces/CommtracNode';
import {CommtracNodeByCnEvent} from '../../../interfaces/CommtracNodeByCnEvent';
import {CommtracNodeHistory} from '../../../interfaces/CommtracNodeHistory';
import {Node} from '../../../interfaces/Node';
import reduxSelectors from '../../../redux/selectors';
import {
  ALARM_MODULE_COLORS,
  ALARM_MODULE_TYPES,
} from '../../../utils/alarm-modules';
import {generatePickColorSet} from '../../../utils/heatmap';
import {COLORED_LEAFLET_ICONS} from '../../../utils/icons';
import {fetchMapConfig, saveMapConfig} from '../../../utils/map';
import {
  getCommtracNodeByCnHistoryId,
  getCommtracNodeHistoryId,
} from '../../../utils/message-types';
import {findTimingOrRoutingGroup} from '../../../utils/nodes';
import {HazardHeatMapData} from '../../dashboard-panels/HazardHeatmap/HazardHeatmapGrid';
import {HeatMapLayer} from '../../dashboard-panels/HazardHeatmap/HeatMapLayer';
import AlarmTooltipContent from './AlarmTooltipContent';
import CommtracNodeHistoryTooltipContent from './CommtracNodeHistoryTooltipContent';
import {RotatedMarker} from './RotatedMarker';
import TempItemMarker from './TempItemMarker';
const greenArrowIcon = require('../../../images/arrows/caret-left-green.png');
const redArrowIcon = require('../../../images/arrows/caret-left-red.png');

export interface MapEmbedConfiguration {
  zoom: number;
  bounds: [[number, number], [number, number]];
  panel?:
    | 'assets_tracking_report'
    | 'employee_tracking_report'
    | 'alarm_log_report'
    | 'cn_report'
    | 'nodes_report'
    | 'warning_hazard_report'
    | 'detection_log_report'
    | 'heatmap_report'
    | 'heatmap_event_logs'
    | 'cn_tracking_report';
  autoFocus: boolean;
}

interface Props extends BoxProps {
  panel?:
    | 'assets_tracking_report'
    | 'employee_tracking_report'
    | 'alarm_log_report'
    | 'cn_report'
    | 'nodes_report'
    | 'cn_tracking_report';
  heatMap?: HazardHeatMapData[];
  alarmNodes?: AlarmLogNode[];
  commtracNodes?: CommtracNode[];
  nodes?: Node[] | null;
  employeeHistoryData?: CommtracNodeHistory[] | null;
  assetHistoryData?: CommtracNodeHistory[] | null;
  commtracNodeByCnEventsData?: CommtracNodeByCnEvent[] | null;
  selectedMapLayers?: MapLayerId[];
  availableMapLayers?: MapLayerId[];
  selectedLevel?: number | null;
  disableEditMode?: boolean;
  onSelectMapLayers?: (value: MapLayerId[]) => void;
  onSelectLevel?: (value: number | null) => void;
  onGetClickCoordinates?: (value: MapLatLangCoordinates) => void;
  onOpenItem?: (
    id: number,
    type: 'cn' | 'wifi' | 'employee' | 'asset' | 'alarm'
  ) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
}

const mousePosition = new MousePosition({
  position: 'bottomleft',
  customComponent: ({latlng}) => {
    return latlng.lat || latlng.lng ? (
      <div style={{color: 'black'}}>
        {latlng.lng.toFixed(6)}, {latlng.lat.toFixed(6)}
      </div>
    ) : null;
  },
});

const mapLayers = [
  {id: 'street', label: 'Street map'},
  {id: 'mine', label: 'Mine'},
  {id: 'nodes', label: 'Nodes'},
  {id: 'routing_network', label: 'Routing Network'},
  {id: 'timing_network', label: 'Timing Network'},
  {id: 'new_devices', label: 'New Devices'},
  {id: 'alarms', label: 'Alarm Modules'},
  {id: 'assets', label: 'Assets'},
  {id: 'employees', label: 'Employees'},
  {id: 'employee_history', label: 'Employee History'},
  {id: 'asset_history', label: 'Asset History'},
  {id: 'node_history', label: 'Nodes'},
  {id: 'commtrac_node_by_cn_history', label: 'Employee/Asset History'},
  {id: 'heatmap', label: 'HeatMap'},
] as const;

export type MapLayerId = (typeof mapLayers)[number]['id'];
export type MapLatLangCoordinates = {
  lat: number | null;
  lng: number | null;
};

// eslint-disable-next-line complexity
export const Map = ({
  panel,
  heatMap,
  alarmNodes: shownAlarmNodes,
  commtracNodes: shownCommtracNodes,
  nodes: shownNodes,
  employeeHistoryData,
  assetHistoryData,
  commtracNodeByCnEventsData,
  selectedMapLayers,
  selectedLevel,
  availableMapLayers: availableMapLayersIds,
  disableEditMode,
  onSelectMapLayers,
  onSelectLevel,
  onGetClickCoordinates,
  onOpenItem,
  onOpenHistory,
  ...props
}: Props) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);
  const [isEnableAutoFocusMode, setIsEnableAutoFocusMode] = useState(
    fetchMapConfig(panel)?.autoFocus ?? true
  );
  const [map, setMap] = useState<any>(null);
  const [mapZoomLevel, setMapZoomLevel] = useState(12);

  useEffect(() => {
    setIsEnableAutoFocusMode(fetchMapConfig(panel)?.autoFocus ?? true);
  }, [panel]);

  const commtracNodes = shownCommtracNodes ?? assets.commtrac_nodes;
  const nodes = shownNodes ?? assets.nodes;
  const alarmNodes = shownAlarmNodes ?? assets.alarm_logs;
  const acknowledgedOrInvalidPostionNodes = alarmNodes?.filter(
    (it) => it?.acknowledged === 0 || !it?.latitude || !it?.longitude
  );
  // Layers
  const [internalSelectedMapLayers, setInternalSelectedMapLayers] = useState(
    mapLayers.map((i) => i.id)
  );
  const [realFitBounds, setRealFitBound] = useState<LatLngBounds | null>(null);
  const computedSelectedMapLayers =
    selectedMapLayers ?? internalSelectedMapLayers;

  const handleSelectMapLayers = (ids: MapLayerId[]) => {
    if (selectedMapLayers !== undefined) {
      setInternalSelectedMapLayers(ids);
    }
    onSelectMapLayers?.(ids);
  };

  // Levels
  const [internalSelectedLevel, setInternalSelectedLevel] = useState<
    number | null
  >(null);
  const computedSelectedLevel = selectedLevel ?? internalSelectedLevel;
  const selectedLevelOption = useMemo(
    () =>
      computedSelectedLevel
        ? assets.layers?.find((i) => i.id === computedSelectedLevel)
        : null,
    [computedSelectedLevel, assets.layers]
  );

  const handleSelectLevel = (id: number | null) => {
    if (selectedLevel !== undefined) {
      setInternalSelectedLevel(id);
    }
    onSelectLevel?.(id);
  };

  useEffect(() => {
    if (assets?.layers?.length === 1) {
      handleSelectLevel(assets?.layers[0].id);
    }
  }, [assets?.layers]);

  const commtracNodeEmployees = useMemo(
    () => commtracNodes?.filter((i) => i.type === 'miner'),
    [commtracNodes]
  );
  const commtracNodeAssets = useMemo(
    () => commtracNodes?.filter((i) => i.type === 'asset'),
    [commtracNodes]
  );

  const newDevices = useMemo(() => {
    return nodes?.filter((it) => it?.ack === '0');
  }, [nodes]);

  const itemsGroup = useMemo(() => {
    const proceedItems: string[] = [];
    const group: {
      key: string;
      items: any[];
    }[] = [];
    let allItems: any[] = [];
    if (map) {
      if (computedSelectedMapLayers?.includes('employees')) {
        allItems = [
          ...allItems,
          ...commtracNodeEmployees?.map((it) => ({
            ...it,
            item_type: 'employee',
            item_uniq_id: `employee-${it.id}`,
          })),
        ];
      }

      if (computedSelectedMapLayers?.includes('assets')) {
        allItems = [
          ...allItems,
          ...commtracNodeAssets?.map((it) => ({
            ...it,
            item_type: 'asset',
            item_uniq_id: `asset-${it.id}`,
          })),
        ];
      }

      if (computedSelectedMapLayers?.includes('nodes')) {
        allItems = [
          ...allItems,
          ...nodes?.map((it) => ({
            ...it,
            item_type: 'node',
            item_uniq_id:
              it.node_type === 'communication_node'
                ? `cn-${it.cn_id}`
                : it.node_type === 'wifi_point'
                  ? `wifi-${it.wifi_id}`
                  : `node-${it.id}`,
          })),
        ];
      }

      if (computedSelectedMapLayers?.includes('new_devices')) {
        allItems = [
          ...allItems,
          ...newDevices?.map((it) => ({
            ...it,
            item_type: 'new_devices',
            item_uniq_id: `new-node-${it.cn_id}`,
          })),
        ];
      }

      for (let i = 0; i < allItems.length; i++) {
        if (proceedItems.includes(allItems[i]?.item_uniq_id ?? null)) {
          continue;
        }
        const baseItem = {
          ...allItems[i],
        };
        const stuckItems = allItems.filter((it) => {
          const point1 = map.latLngToContainerPoint(
            it?.item_type === 'asset' || it?.item_type === 'employee'
              ? new LatLng(it.commtrac_pos_lat ?? 0, it.commtrac_pos_lon ?? 0)
              : new LatLng(it.latitude, it.longitude)
          );
          const point2 = map.latLngToContainerPoint(
            baseItem?.item_type === 'asset' ||
              baseItem?.item_type === 'employee'
              ? new LatLng(
                  baseItem.commtrac_pos_lat ?? 0,
                  baseItem.commtrac_pos_lon ?? 0
                )
              : new LatLng(baseItem.latitude, baseItem.longitude)
          );

          return (
            Math.abs(point1.distanceTo(point2)) <= 10 &&
            `${it.item_uniq_id}` !== `${baseItem.item_uniq_id}` &&
            !proceedItems?.includes(it?.item_uniq_id)
          );
        });

        group.push({
          key: `${uniqueId('cn-employee')}`,
          items: [...stuckItems, baseItem],
        });

        [...stuckItems, baseItem]
          .map((it) => it.item_uniq_id ?? null)
          .forEach((it) => proceedItems.push(it));
      }
      return group;
    } else {
      return [];
    }
  }, [
    nodes,
    commtracNodeEmployees,
    commtracNodeAssets,
    newDevices,
    map,
    mapZoomLevel,
    computedSelectedMapLayers,
  ]);

  const employeesPerLayer = useMemo(
    () =>
      _.chain(assets.layers)
        .keyBy('id')
        .mapValues(() =>
          shownCommtracNodes ? commtracNodeEmployees?.filter(() => true) : null
        )
        .value(),
    [commtracNodeEmployees, assets.layers]
  );

  const nodesPerLayer = useMemo(
    () =>
      _.chain(assets.layers)
        .keyBy('id')
        .mapValues(() => (shownNodes ? nodes?.filter(() => true) : null))
        .value(),
    [nodes, assets.layers]
  );

  const assetsPerLayer = useMemo(
    () =>
      _.chain(assets.layers)
        .keyBy('id')
        .mapValues(() =>
          shownCommtracNodes ? commtracNodeAssets?.filter(() => true) : null
        )
        .value(),
    [commtracNodeAssets, assets.layers]
  );

  const employeeHistoryPerLayer = useMemo(
    () =>
      _.chain(assets.layers)
        .keyBy('id')
        .mapValues(() => employeeHistoryData?.filter(() => true))
        .value(),
    [employeeHistoryData, assets.layers]
  );

  const assetHistoryPerLayer = useMemo(
    () =>
      _.chain(assets.layers)
        .keyBy('id')
        .mapValues(() => assetHistoryData?.filter(() => true))
        .value(),
    [assetHistoryData, assets.layers]
  );

  const commtracNodeByCnEventsPerLayer = useMemo(
    () =>
      _.chain(assets.layers)
        .keyBy('id')
        .mapValues(() => commtracNodeByCnEventsData?.filter(() => true))
        .value(),
    [commtracNodeByCnEventsData, assets.layers]
  );

  const alarmLogsPerLayer = useMemo(
    () =>
      _.chain(assets.layers)
        .keyBy('id')
        .mapValues(() => alarmNodes?.filter(() => true))
        .value(),
    [alarmNodes, assets.layers]
  );

  // Leeflet

  const {ref, width, height} = useComponentSize();
  const minLayerBounds = useMemo(
    () =>
      selectedLevelOption
        ? latLngBounds(
            [+selectedLevelOption.map.minx, +selectedLevelOption.map.miny],
            [+selectedLevelOption.map.maxx, +selectedLevelOption.map.maxy]
          )
        : undefined,
    [selectedLevel]
  );

  const [topRightPositon, setTopRightPosition] = useState<any | null>(null);

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        onGetClickCoordinates?.(e.latlng);
      },

      moveend() {
        if (map) {
          setMapZoomLevel(map.getZoom());
          saveMapConfig(map, panel, isEnableAutoFocusMode);
        }
      },
    });
    return null;
  };

  useEffect(() => {
    const updateMarkerPosition = () => {
      // const mapSize = map.getSize();
      if (selectedLevelOption) {
        const topRightPoint = [
          +selectedLevelOption?.map.maxx,
          +selectedLevelOption?.map.maxy,
        ];
        // Set marker position
        setTopRightPosition(topRightPoint);
      } else {
        setTopRightPosition(null);
      }
    };

    if (map) {
      map.on('resize move', updateMarkerPosition);
      updateMarkerPosition();
    }
    return () => {
      if (map) {
        map.off('resize move', updateMarkerPosition);
      }
    };
  }, [map, selectedLevelOption]);

  // map icons
  const configurations = useConfigurations();
  const getAlarmLogIcon = (item: AlarmLogNode) => {
    let color: string | number | undefined;
    if (item?.status === 'active') {
      color = 'blue';
    }
    if (item?.status === 'inactive') {
      color = 'gray';
    }
    if (item?.e_701) {
      color =
        configurations[
          ALARM_MODULE_COLORS[ALARM_MODULE_TYPES.ALARM_NO_HEARTBEAT] ?? ''
        ]?.value ?? 'default';
    }
    if (item?.e_702) {
      color =
        configurations[
          ALARM_MODULE_COLORS[ALARM_MODULE_TYPES.ALARM_NEW_MODULE_DETECTED] ??
            ''
        ]?.value ?? 'gray';
    }
    if (item?.e_704) {
      color =
        configurations[
          ALARM_MODULE_COLORS[ALARM_MODULE_TYPES.ALARM_ENABLED_WARNING_TYPES] ??
            ''
        ]?.value ?? 'yellow';
    }
    if (item?.e_703) {
      color =
        configurations[
          ALARM_MODULE_COLORS[ALARM_MODULE_TYPES.ALARM_ENABLED_ALARM_TYPES] ??
            ''
        ]?.value ?? 'red';
    }

    return COLORED_LEAFLET_ICONS.alarm[color ?? 'gray'];
  };

  const getEmployeeHistoryIcon = () => {
    return COLORED_LEAFLET_ICONS.miners.default;
  };

  const getAssetHistoryIcon = () => {
    return COLORED_LEAFLET_ICONS.assetEvents.default;
  };

  const getCommtracNodeByCnHistoryIcon = (item: CommtracNodeByCnEvent) => {
    if (item.type === 0) {
      return COLORED_LEAFLET_ICONS.assets.default;
    }
    return COLORED_LEAFLET_ICONS.miners.default;
  };

  useEffect(() => {
    if (map) {
      mousePosition.addTo(map);
    }
    return () => {
      mousePosition.remove();
    };
  }, [map]);

  useEffect(() => {
    map?.invalidateSize();
  }, [width, height]);

  // Layer and level
  const [layerMenuAnchorEl, setLayerMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const layerMenuOpen = Boolean(layerMenuAnchorEl);

  const availableMapLayers = useMemo(() => {
    if (availableMapLayersIds?.length) {
      return mapLayers.filter((i) => availableMapLayersIds.includes(i.id));
    }
    return mapLayers;
  }, [availableMapLayersIds]);

  const fitBoundRange = useMemo(() => {
    let minLng: number = 180;
    let maxLng: number = -180;
    let minLat: number = 90;
    let maxLat: number = -90;
    let totalMarkers: number = 0;

    if (computedSelectedMapLayers?.includes('alarms')) {
      alarmNodes?.forEach((it: AlarmLogNode) => {
        minLng = Math.min(minLng, +(it?.longitude ?? 0));
        maxLng = Math.max(maxLng, +(it?.longitude ?? 0));
        minLat = Math.min(minLat, +(it?.latitude ?? 0));
        maxLat = Math.max(maxLat, +(it?.latitude ?? 0));
      });
      totalMarkers += alarmNodes.length;
    }

    if (computedSelectedMapLayers?.includes('employees')) {
      commtracNodeEmployees?.forEach((it) => {
        minLng = Math.min(minLng, +(it?.commtrac_pos_lon ?? 0));
        maxLng = Math.max(maxLng, +(it?.commtrac_pos_lon ?? 0));
        minLat = Math.min(minLat, +(it?.commtrac_pos_lat ?? 0));
        maxLat = Math.max(maxLat, +(it?.commtrac_pos_lat ?? 0));
      });
      totalMarkers += commtracNodeEmployees.length;
    }

    if (computedSelectedMapLayers?.includes('assets')) {
      commtracNodeAssets?.forEach((it) => {
        minLng = Math.min(minLng, +(it?.commtrac_pos_lon ?? 0));
        maxLng = Math.max(maxLng, +(it?.commtrac_pos_lon ?? 0));
        minLat = Math.min(minLat, +(it?.commtrac_pos_lat ?? 0));
        maxLat = Math.max(maxLat, +(it?.commtrac_pos_lat ?? 0));
      });
      totalMarkers += commtracNodeAssets.length;
    }

    if (computedSelectedMapLayers?.includes('nodes')) {
      nodes?.forEach((it) => {
        minLng = Math.min(minLng, +(it?.longitude ?? 0));
        maxLng = Math.max(maxLng, +(it?.longitude ?? 0));
        minLat = Math.min(minLat, +(it?.latitude ?? 0));
        maxLat = Math.max(maxLat, +(it?.latitude ?? 0));
      });
      totalMarkers += nodes.length;
    }

    if (computedSelectedMapLayers?.includes('employee_history')) {
      employeeHistoryData?.forEach((it) => {
        minLng = Math.min(
          minLng,
          +(it.longitude || (selectedLevelOption?.map.maxy ?? 0))
        );
        maxLng = Math.max(
          maxLng,
          +(it.longitude || (selectedLevelOption?.map.maxy ?? 0))
        );
        minLat = Math.min(
          minLat,
          +(it.latitude || (selectedLevelOption?.map.maxx ?? 0))
        );
        maxLat = Math.max(
          maxLat,
          +(it.latitude || (selectedLevelOption?.map.maxx ?? 0))
        );
      });
      totalMarkers += employeeHistoryData?.length ?? 0;
    }

    if (computedSelectedMapLayers?.includes('asset_history')) {
      assetHistoryData?.forEach((it) => {
        minLng = Math.min(
          minLng,
          +(it.longitude || (selectedLevelOption?.map.maxy ?? 0))
        );
        maxLng = Math.max(
          maxLng,
          +(it.longitude || (selectedLevelOption?.map.maxy ?? 0))
        );
        minLat = Math.min(
          minLat,
          +(it.latitude || (selectedLevelOption?.map.maxx ?? 0))
        );
        maxLat = Math.max(
          maxLat,
          +(it.latitude || (selectedLevelOption?.map.maxx ?? 0))
        );
      });
      totalMarkers += assetHistoryData?.length ?? 0;
    }

    if (computedSelectedMapLayers?.includes('commtrac_node_by_cn_history')) {
      commtracNodeByCnEventsData?.forEach((it) => {
        minLng = Math.min(
          minLng,
          +(it.pos_lon || (selectedLevelOption?.map.maxy ?? 0))
        );
        maxLng = Math.max(
          maxLng,
          +(it.pos_lon || (selectedLevelOption?.map.maxy ?? 0))
        );
        minLat = Math.min(
          minLat,
          +(it.pos_lat || (selectedLevelOption?.map.maxx ?? 0))
        );
        maxLat = Math.max(
          maxLat,
          +(it.pos_lat || (selectedLevelOption?.map.maxx ?? 0))
        );
      });
      totalMarkers += commtracNodeByCnEventsData?.length ?? 0;
    }

    const mapConfig = fetchMapConfig(panel);
    if (totalMarkers < 1) {
      minLng = Number(assets?.company?.miny ?? -180);
      maxLng = Number(assets?.company?.maxy ?? 180);
      minLat = Number(assets?.company?.minx ?? -90);
      maxLat = Number(assets?.company?.maxx ?? 90);
    }

    if (isEnableAutoFocusMode || !mapConfig) {
      if (
        (acknowledgedOrInvalidPostionNodes.length > 0 || totalMarkers < 1) &&
        computedSelectedMapLayers.includes('mine')
      ) {
        return (
          minLayerBounds ?? latLngBounds([minLat, minLng], [maxLat, maxLng])
        );
      } else if (totalMarkers < 1) {
        return latLngBounds([minLat, minLng], [maxLat, maxLng]);
      }
    } else if (mapConfig && !isEnableAutoFocusMode) {
      return latLngBounds(mapConfig?.bounds[0], mapConfig?.bounds[1]);
    }

    return latLngBounds([minLat, minLng], [maxLat, maxLng]);
  }, [
    assets.company,
    minLayerBounds,
    computedSelectedMapLayers,
    commtracNodeEmployees,
    commtracNodeAssets,
    nodes,
    alarmNodes,
    acknowledgedOrInvalidPostionNodes,
    employeeHistoryData,
    assetHistoryData,
    commtracNodeByCnEventsData,
    isEnableAutoFocusMode,
  ]);

  useEffect(() => {
    setRealFitBound((prev) => {
      if (_.isEqual(prev, fitBoundRange)) {
        return prev;
      }

      return fitBoundRange;
    });
  }, [fitBoundRange]);

  useEffect(() => {
    if (realFitBounds) {
      map ? map.fitBounds(realFitBounds) : '';
    }
  }, [map, realFitBounds]);

  useEffect(() => {
    if (map) {
      saveMapConfig(map, panel, isEnableAutoFocusMode);
    }
  }, [isEnableAutoFocusMode]);

  // Timing & Routing

  const timingNodesGroup = useMemo(() => {
    const timingGroup = findTimingOrRoutingGroup(
      nodes?.filter((it) => !!it?.cn_id),
      'timing'
    );
    return timingGroup;
  }, [nodes]);

  const routingNodesGroup = useMemo(() => {
    const routingGroup = findTimingOrRoutingGroup(
      nodes?.filter((it) => !!it?.cn_id),
      'routing'
    );
    return routingGroup;
  }, [nodes]);

  const calculateRotationAngle = (startPoint: number[], endPoint: number[]) => {
    const deltaLng = endPoint[1] - startPoint[1];
    const deltaLat = endPoint[0] - startPoint[0];
    return 180.0 - (Math.atan2(deltaLat, deltaLng) * 180) / Math.PI;
  };

  const TimingNodeArrowIcon = new Icon({
    iconUrl: redArrowIcon,
    iconSize: [10, 14],
    iconAnchor: [5, 7],
    rotationAngle: 30,
  });

  const RoutingNodeArrowIcon = new Icon({
    iconUrl: greenArrowIcon,
    iconSize: [10, 14],
    iconAnchor: [5, 7],
    rotationAngle: 30,
  });

  return (
    <Box
      ref={ref}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      className="123-cl"
      {...props}
    >
      {selectedLevelOption ? (
        <Box
          width="100%"
          height="100%"
          position="relative"
          sx={{
            '.leaflet-top': {top: 45},
            '.leaflet-tooltip-pane': {zIndex: 750},
            '.duplicate.icon': {
              width: '32px !important',
              height: '37px !important',
              span: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                fontWeight: 500,
              },
            },
          }}
        >
          <Box
            position="absolute"
            zIndex={500}
            top={11}
            left={11}
            display="flex"
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                p: 0.5,
                borderRadius: 2,
                mr: 1,
                minWidth: 'auto',
                color: '#000',
                backgroundColor: '#FFF',
              }}
              onClick={() => handleSelectLevel(null)}
            >
              <ArrowBackIcon />
            </Button>

            <Button
              variant="contained"
              sx={{
                py: 0.5,
                whiteSpace: 'nowrap',
                color: '#000',
                backgroundColor: '#FFF',
                zIndex: 1,
              }}
              onClick={(event) => {
                setLayerMenuAnchorEl(event.currentTarget);
              }}
            >
              {selectedLevelOption?.name} ({computedSelectedMapLayers.length}/
              {availableMapLayers.length} layers)
            </Button>

            <Menu
              anchorEl={layerMenuAnchorEl}
              open={layerMenuOpen}
              onBackdropClick={() => setLayerMenuAnchorEl(null)}
            >
              <Typography
                textTransform="uppercase"
                fontWeight={400}
                width={300}
                color="text.disabled"
                p={2}
                pb={1}
              >
                Layers
              </Typography>

              {availableMapLayers.map((i) => (
                <MenuItem
                  key={i.id}
                  selected={computedSelectedMapLayers.includes(i.id)}
                  onClick={() => {
                    if (computedSelectedMapLayers.includes(i.id)) {
                      handleSelectMapLayers(
                        computedSelectedMapLayers.filter((l) => l !== i.id)
                      );
                    } else {
                      handleSelectMapLayers([
                        ...computedSelectedMapLayers,
                        i.id,
                      ]);
                    }
                  }}
                >
                  {i.label}
                </MenuItem>
              ))}

              <Divider />

              <Typography
                textTransform="uppercase"
                fontWeight={400}
                width={300}
                color="text.disabled"
                p={2}
                pb={1}
              >
                Levels
              </Typography>

              {assets.layers.map((i) => (
                <MenuItem
                  key={i.id}
                  selected={computedSelectedLevel === i.id}
                  onClick={() => {
                    handleSelectLevel(i.id);
                    setLayerMenuAnchorEl(null);
                  }}
                >
                  {i.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <MuiTooltip title="Map Zoom">
            <Button
              variant="contained"
              size="small"
              sx={{
                position: 'absolute',
                zIndex: 500,
                top: 130,
                left: 11,
                p: 0.5,
                borderRadius: 2,
                minWidth: 'auto',
                color: '#000',
                backgroundColor: '#FFF',
              }}
              onClick={() => {
                if (minLayerBounds) {
                  const maxLng = minLayerBounds.getEast();
                  const minLng = minLayerBounds.getWest();
                  const maxLat = minLayerBounds.getNorth();
                  const minLat = minLayerBounds.getSouth();
                  map.fitBounds(
                    latLngBounds([minLat, minLng], [maxLat, maxLng])
                  );
                } else if (selectedLevelOption) {
                  map.fitBounds(
                    latLngBounds(
                      [
                        +selectedLevelOption.map.minx,
                        +selectedLevelOption.map.miny,
                      ],
                      [
                        +selectedLevelOption.map.maxx,
                        +selectedLevelOption.map.maxy,
                      ]
                    )
                  );
                }
              }}
            >
              <LocationSearchingIcon />
            </Button>
          </MuiTooltip>

          <MuiTooltip title="Auto Zoom">
            <Button
              variant="contained"
              size="small"
              sx={{
                position: 'absolute',
                zIndex: 500,
                top: 170,
                left: 11,
                p: 0.5,
                borderRadius: 2,
                minWidth: 'auto',
                color: '#000',
                backgroundColor: !isEnableAutoFocusMode ? '#FFF' : 'main',
              }}
              onClick={() => setIsEnableAutoFocusMode(!isEnableAutoFocusMode)}
            >
              <ZoomInMapTwoTone />
            </Button>
          </MuiTooltip>

          <MapContainer
            ref={setMap}
            bounds={fitBoundRange}
            zoom={12}
            maxZoom={18}
            style={{width: '100%', height: '100%'}}
          >
            <>
              {computedSelectedMapLayers?.includes('street') && (
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              )}

              {computedSelectedMapLayers?.includes('mine') && (
                <TileLayer
                  url={`${mineMapBaseUrl}/${selectedLevelOption.map.company_id}/${selectedLevelOption.map.path}/map/{z}/{x}/{-y}.png`}
                  opacity={0.7}
                />
              )}

              {computedSelectedMapLayers?.includes('timing_network') &&
                timingNodesGroup?.map((it, index: number) => {
                  return (
                    <Fragment key={index}>
                      {it.map(
                        (_, index, array) =>
                          index < array.length - 1 && (
                            <Fragment key={index}>
                              <Polyline
                                positions={[
                                  [
                                    array[index].latitude,
                                    array[index].longitude,
                                  ],
                                  [
                                    array[index + 1].latitude,
                                    array[index + 1].longitude,
                                  ],
                                ]}
                                color="rgb(200, 0, 0)"
                                pathOptions={{
                                  lineCap: 'butt',
                                }}
                                weight={3}
                              />
                              <RotatedMarker
                                position={[
                                  array[index + 1].latitude,
                                  array[index + 1].longitude,
                                ]}
                                icon={TimingNodeArrowIcon}
                                rotationAngle={calculateRotationAngle(
                                  [
                                    array[index].latitude,
                                    array[index].longitude,
                                  ],
                                  [
                                    array[index + 1].latitude,
                                    array[index + 1].longitude,
                                  ]
                                )}
                                rotationOrigin="center center"
                              ></RotatedMarker>
                            </Fragment>
                          )
                      )}
                    </Fragment>
                  );
                })}
              {computedSelectedMapLayers?.includes('heatmap') &&
                heatMap &&
                heatMap?.length > 0 &&
                heatMap
                  ?.filter((it) => !!it.isSelected)
                  .map((it, index: number) => {
                    return (
                      <HeatMapLayer
                        id={`map-${index}`}
                        data={it.address_points}
                        options={{
                          radius: it.configuration?.radius ?? 25,
                          blur: it.configuration?.blur ?? 15,
                          max: 0.001,
                          gadient: it.configuration?.color_gradients
                            ? generatePickColorSet(
                                it.configuration.color_gradients
                              )
                            : null,
                        }}
                        key={index}
                        map={map}
                      />
                    );
                  })}
              {computedSelectedMapLayers?.includes('routing_network') &&
                routingNodesGroup?.map((it, index: number) => {
                  return (
                    <Fragment key={index}>
                      {it.map(
                        (_, index, array) =>
                          index < array.length - 1 && (
                            <Fragment key={index}>
                              <Polyline
                                positions={[
                                  [
                                    array[index].latitude,
                                    array[index].longitude,
                                  ],
                                  [
                                    array[index + 1].latitude,
                                    array[index + 1].longitude,
                                  ],
                                ]}
                                color="rgb(0, 200, 0)"
                                pathOptions={{
                                  lineCap: 'butt',
                                }}
                                weight={3}
                              />
                              <RotatedMarker
                                position={[
                                  array[index + 1].latitude,
                                  array[index + 1].longitude,
                                ]}
                                icon={RoutingNodeArrowIcon}
                                rotationAngle={calculateRotationAngle(
                                  [
                                    array[index].latitude,
                                    array[index].longitude,
                                  ],
                                  [
                                    array[index + 1].latitude,
                                    array[index + 1].longitude,
                                  ]
                                )}
                                rotationOrigin="center center"
                              ></RotatedMarker>
                            </Fragment>
                          )
                      )}
                    </Fragment>
                  );
                })}

              {(computedSelectedMapLayers.includes('employees') ||
                computedSelectedMapLayers.includes('assets') ||
                computedSelectedMapLayers.includes('nodes') ||
                computedSelectedMapLayers.includes('new_devices')) &&
                itemsGroup?.map((i, index: number) => {
                  return (
                    <TempItemMarker
                      topRightPosition={topRightPositon}
                      key={`item-${index}`}
                      i={i}
                      type={i.items[0].item_type}
                      onOpenItem={onOpenItem}
                      onOpenHistory={onOpenHistory}
                    ></TempItemMarker>
                  );
                })}

              <MapEvents />
              {computedSelectedMapLayers.includes('alarms') &&
                alarmNodes
                  ?.filter(
                    (it) => it?.acknowledged && it?.latitude && it?.longitude
                  )
                  .map((i: AlarmLogNode) => (
                    <Marker
                      key={`node-${i.id}`}
                      position={[
                        i?.latitude ?? minLayerBounds?.getNorthEast()?.lat ?? 0,
                        i?.longitude ??
                          minLayerBounds?.getNorthEast()?.lng ??
                          0,
                      ]}
                      icon={getAlarmLogIcon(i)}
                      interactive
                      eventHandlers={{
                        click: (event) => event.target.openPopup(),
                        popupopen: (event) => {
                          event.target._tooltip.options.opacity = 0;
                        },
                        popupclose: (event) => {
                          event.target._tooltip.options.opacity = 0.9;
                        },
                      }}
                    >
                      <Popup interactive autoClose={false} offset={[0, -32]}>
                        <AlarmTooltipContent
                          item={i}
                          disableEditMode={disableEditMode}
                          onOpenItem={() => {
                            onOpenItem?.(i.id, 'alarm');
                          }}
                          onOpenHistory={() => {
                            onOpenHistory?.(i.id, 'alarm');
                          }}
                        />
                      </Popup>

                      <Tooltip
                        direction="top"
                        offset={[0, -34]}
                        pane="popupPane"
                      >
                        <AlarmTooltipContent
                          item={i}
                          disableEditMode={disableEditMode}
                          onOpenItem={() => {
                            onOpenItem?.(i.id, 'alarm');
                          }}
                          onOpenHistory={() => {
                            onOpenHistory?.(i.id, 'alarm');
                          }}
                        />
                      </Tooltip>
                    </Marker>
                  ))}

              {computedSelectedMapLayers.includes('alarms') &&
                computedSelectedMapLayers.includes('mine') &&
                acknowledgedOrInvalidPostionNodes.map((i: AlarmLogNode) => {
                  return (
                    <Marker
                      key={`node-${i.id}`}
                      position={topRightPositon ? topRightPositon : [0, 0]}
                      icon={getAlarmLogIcon(i)}
                      interactive
                      eventHandlers={{
                        click: (event) => event.target.openPopup(),
                        popupopen: (event) => {
                          event.target._tooltip.options.opacity = 0;
                        },
                        popupclose: (event) => {
                          event.target._tooltip.options.opacity = 0.9;
                        },
                      }}
                    >
                      <Popup interactive autoClose={false} offset={[0, -32]}>
                        <AlarmTooltipContent
                          item={i}
                          disableEditMode={disableEditMode}
                          onOpenItem={() => {
                            onOpenItem?.(i.id, 'alarm');
                          }}
                          onOpenHistory={() => {
                            onOpenHistory?.(i.id, 'alarm');
                          }}
                        />
                      </Popup>

                      <Tooltip
                        direction="top"
                        offset={[0, -34]}
                        pane="popupPane"
                      >
                        <AlarmTooltipContent
                          item={i}
                          disableEditMode={disableEditMode}
                          onOpenItem={() => {
                            onOpenItem?.(i.id, 'alarm');
                          }}
                          onOpenHistory={() => {
                            onOpenHistory?.(i.id, 'alarm');
                          }}
                        />
                      </Tooltip>
                    </Marker>
                  );
                })}

              {computedSelectedMapLayers.includes('employee_history') &&
                employeeHistoryData?.map((i) => (
                  <Marker
                    key={`commtrac-node-history-employee-${getCommtracNodeHistoryId(i)}`}
                    position={[
                      +(i.latitude || selectedLevelOption.map.maxx),
                      +(i.longitude || selectedLevelOption.map.maxy),
                    ]}
                    icon={getEmployeeHistoryIcon()}
                    eventHandlers={{
                      click: (event) => event.target.openPopup(),
                      popupopen: (event) => {
                        event.target._tooltip.options.opacity = 0;
                      },
                      popupclose: (event) => {
                        event.target._tooltip.options.opacity = 0.9;
                      },
                    }}
                  >
                    <Popup interactive autoClose={false} offset={[0, -32]}>
                      <CommtracNodeHistoryTooltipContent
                        timestamp={i.timestamp}
                      />
                    </Popup>

                    <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                      <CommtracNodeHistoryTooltipContent
                        timestamp={i.timestamp}
                      />
                    </Tooltip>
                  </Marker>
                ))}

              {computedSelectedMapLayers.includes('asset_history') &&
                assetHistoryData?.map((i) => (
                  <Marker
                    key={`commtrac-node-history-asset-${getCommtracNodeHistoryId(i)}`}
                    position={[
                      +(i.latitude || selectedLevelOption.map.maxx),
                      +(i.longitude || selectedLevelOption.map.maxy),
                    ]}
                    icon={getAssetHistoryIcon()}
                    eventHandlers={{
                      click: (event) => event.target.openPopup(),
                      popupopen: (event) => {
                        event.target._tooltip.options.opacity = 0;
                      },
                      popupclose: (event) => {
                        event.target._tooltip.options.opacity = 0.9;
                      },
                    }}
                  >
                    <Popup interactive autoClose={false} offset={[0, -32]}>
                      <CommtracNodeHistoryTooltipContent
                        timestamp={i.timestamp}
                      />
                    </Popup>

                    <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                      <CommtracNodeHistoryTooltipContent
                        timestamp={i.timestamp}
                      />
                    </Tooltip>
                  </Marker>
                ))}

              {computedSelectedMapLayers.includes(
                'commtrac_node_by_cn_history'
              ) &&
                commtracNodeByCnEventsData?.map((i) => (
                  <Marker
                    key={`commtrac-node-by-cn-history-${getCommtracNodeByCnHistoryId(i)}`}
                    position={[
                      +(i.pos_lat || selectedLevelOption.map.maxx),
                      +(i.pos_lon || selectedLevelOption.map.maxy),
                    ]}
                    icon={getCommtracNodeByCnHistoryIcon(i)}
                    eventHandlers={{
                      click: (event) => event.target.openPopup(),
                      popupopen: (event) => {
                        event.target._tooltip.options.opacity = 0;
                      },
                      popupclose: (event) => {
                        event.target._tooltip.options.opacity = 0.9;
                      },
                    }}
                  >
                    <Popup interactive autoClose={false} offset={[0, -32]}>
                      <CommtracNodeHistoryTooltipContent timestamp={i.date} />
                    </Popup>

                    <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
                      <CommtracNodeHistoryTooltipContent timestamp={i.date} />
                    </Tooltip>
                  </Marker>
                ))}
            </>
          </MapContainer>
        </Box>
      ) : (
        <Box overflow="auto" height="100%">
          {assets.layers.map((layer) => (
            <Box
              key={layer.id}
              height={`${100 / assets.layers.length}%`}
              minHeight={140}
              width="100%"
              position="relative"
              boxShadow="0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)"
              bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
              sx={{cursor: 'pointer'}}
              onClick={() => handleSelectLevel(layer.id)}
            >
              <Backdrop
                open={true}
                sx={{position: 'absolute', zIndex: 400, bgcolor: 'transparent'}}
              >
                <Box textAlign="center">
                  <Box fontSize={22} fontWeight={700}>
                    {layer.name}
                  </Box>

                  {employeesPerLayer[layer.id] && (
                    <Box>Employees: {employeesPerLayer[layer.id]?.length}</Box>
                  )}

                  {employeeHistoryPerLayer[layer.id] && (
                    <Box>
                      Emplyee History:{' '}
                      {employeeHistoryPerLayer[layer.id]?.length}
                    </Box>
                  )}

                  {nodesPerLayer[layer.id] && (
                    <Box>Nodes: {nodesPerLayer[layer.id]?.length}</Box>
                  )}

                  {assetsPerLayer[layer.id] && (
                    <Box>Assets: {assetsPerLayer[layer.id]?.length}</Box>
                  )}

                  {assetHistoryPerLayer[layer.id] && (
                    <Box>
                      Asset History: {assetHistoryPerLayer[layer.id]?.length}
                    </Box>
                  )}

                  {commtracNodeByCnEventsPerLayer[layer.id] && (
                    <Box>
                      Employees/Assets History:{' '}
                      {commtracNodeByCnEventsPerLayer[layer.id]?.length}
                    </Box>
                  )}
                  {selectedMapLayers?.includes('alarms') &&
                    alarmLogsPerLayer[layer.id] && (
                      <Box>
                        Alarm Modules: {alarmLogsPerLayer[layer.id]?.length}
                      </Box>
                    )}
                </Box>
              </Backdrop>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
