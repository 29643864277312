/* eslint-disable @typescript-eslint/no-unused-vars */
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Button, Divider, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import {AssetHuman} from '../../interfaces/AssetHumanAdmin';
import AccessControl from '../common/AccessControl';
import ModalDraggable from '../common/ModalDraggable';
import CommtracNodeItemsPurgeEventsButton from '../commtrac-nodes/buttons/CommtracNodeItemsPurgeEventsButton';
import CommtracNodeItemsPurgeNodeButton from '../commtrac-nodes/buttons/CommtracNodeItemsPurgeNodeButton';
import EmployeeItemsPurgeChatEventsButton from '../employees/buttons/EmployeeItemsPurgeChatEventsButton';
import AssetHumanItemUpsert from './AssetHumanItemUpsert';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk?: number;
  item?: AssetHuman;
  mode?: 'view' | 'update' | 'chat';
  prefetch?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: () => void;
  onOpen?: () => void;
  onClose?: Function;
}

const AssetHumanItemEditButton = <T extends ComponentType = typeof Button>({
  pk,
  item,
  mode,
  prefetch,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged,
  onOpenHistory,
  onOpen,
  onClose,
}: Props<T>) => {
  console.log(item);
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;
  const [isOpened, setIsOpened] = useState(false);
  const employeeIds = useMemo(() => {
    if (item?.commtrac_node?.type === 'miner') {
      return [item?.commtrac_node.id];
    } else {
      return [];
    }
  }, [item]);

  const assetIds = useMemo(() => {
    if (item?.commtrac_node?.type === 'asset') {
      return [item?.commtrac_node.id];
    } else {
      return [];
    }
  }, [item]);
  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem
          onClick={() => {
            setIsOpened(true);
            setMenuAnchorEl(undefined);
          }}
        >
          <EditIcon fontSize="small" sx={{mr: 1.5}} />
          {`Edit ${item?.commtrac_node?.type === 'miner' ? 'Employee' : 'Asset'}`}
        </MenuItem>
        <AccessControl permissions={['post::/purge']}>
          <Divider />
          <CommtracNodeItemsPurgeNodeButton
            employeeIds={employeeIds}
            assetIds={assetIds}
            component={MenuItem}
            onDone={() => {
              setMenuAnchorEl(undefined);
              onSubmitted?.();
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 2}} />
            Purge All
          </CommtracNodeItemsPurgeNodeButton>

          <CommtracNodeItemsPurgeEventsButton
            employeeIds={employeeIds}
            assetIds={assetIds}
            component={MenuItem}
            onDone={() => {
              setMenuAnchorEl(undefined);
              onSubmitted?.();
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 2}} />
            Purge Transactions
          </CommtracNodeItemsPurgeEventsButton>
        </AccessControl>

        {employeeIds.length ? (
          <EmployeeItemsPurgeChatEventsButton
            ids={employeeIds}
            component={MenuItem}
            onDone={() => {
              setMenuAnchorEl(undefined);
              onSubmitted?.();
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 2}} />
            Chat
          </EmployeeItemsPurgeChatEventsButton>
        ) : null}
      </Menu>
      {isOpened ? (
        <ModalDraggable open={isOpened}>
          <AssetHumanItemUpsert
            pk={pk}
            item={item}
            mode={mode}
            prefetch={prefetch}
            onClose={() => {
              setIsOpened(false);
              onClose?.();
            }}
            onSubmitted={() => {
              onSubmitted?.();
              if (!pk) {
                setIsOpened(false);
                onClose?.();
              }
            }}
            onPurged={onPurged}
            onOpenHistory={onOpenHistory}
          />
        </ModalDraggable>
      ) : null}
    </>
  );
};

export default AssetHumanItemEditButton;
